import AdminLayout from "../../components/AdminLayout";
import MDBox from "../../components/MDBox";
import React, {useEffect, useState} from "react";
import {useApi} from "../../services/helpers";
import pxToRem from "../../assets/theme/functions/pxToRem";
import avatar from "../../assets/images/Ellipse.png";
import {Avatar} from "@mui/material";
import DataTableSimple from "../../components/DataTableSimple";
import ActionsMenu from "../../components/ActionsMenu";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";
import MDTypography from "../../components/MDTypography";
import colors from "assets/theme/base/colors";

export const dataTableModel = {
  columns: [
    {Header: "Profile", accessor: "profile", width: `${pxToRem(200)}`},
    {Header: "Broadcasts", accessor: "broadcasts", width: `${pxToRem(320)}`},
    {Header: "Status", accessor: "status", width: `${pxToRem(80)}`},
    {Header: "Action", accessor: "action", width: `${pxToRem(50)}`},
  ],
  rows: [],
};

function AdminBroadcasts() {
  const api = useApi();
  const [loading, setLoading] = useState(false);

  const [table, setTable] = useState(dataTableModel);
  const [paginationData, setPaginationData] = useState({
    counts: 0,
    itemsPerPage: 0,
    currentPage: 1,
  });
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const [dataView, setDataView] = useState(null);
  const [broadcast, setBroadcast] = useState(null);
  const handleCloseConfirmation = () => {
    setOpenConfirmation(false);
  };
  const handleConfirmAction = () => {
    setOpenConfirmation(false);
    confirmAction(broadcast);
  };

  const getAllBroadcasts = () => {
    setLoading(true);
    api.getAllBroadcasts().handle({
      onSuccess: (result) => {
        console.log("result: ", result);
        const results = result?.data;
        const data = results.map((d) => ({
          profile: (
            <MDBox
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-around"}
              alignItems={"center"}
              gap={"10px"}
            >
              <Avatar src={d?.author?.profile_picture} alt={avatar} />
              <MDTypography
                sx={{
                  fontFamily: "OpenSans",
                  fontSize: "14px",
                  color: "white",
                }}
              >
                {d?.author?.full_name ?? "No data"}
              </MDTypography>
            </MDBox>
          ),
          broadcasts: d.message ?? "No data",
          status: (
            <MDTypography
              sx={{
                fontFamily: "OpenSans",
                fontSize: "14px",
                color: `${
                  d.status === "APPROVED"
                    ? colors.success.main
                    : d.status === "REJECTED"
                    ? colors.error.main
                    : "white"
                }`,
              }}
            >
              {d.status ?? "No data"}
            </MDTypography>
          ),
          action: (
            <>
              <ActionsMenu
                menuContent={[
                  {
                    title: "Approve",
                    onClick: () => {
                      setBroadcast({...d, status: "APPROVED"});
                      setOpenConfirmation(true);
                    },
                  },
                  {
                    title: "Deny",
                    onClick: () => {
                      setBroadcast({...d, status: "REJECTED"});
                      setOpenConfirmation(true);
                    },
                  },
                  {
                    title: "Need Details",
                    onClick: () => {
                      setBroadcast({...d, status: "AWAITING"});
                      setOpenConfirmation(true);
                    },
                  },
                ]}
              />
            </>
          ),
        }));

        setTable({...dataTableModel, rows: data});
        setPaginationData({
          counts: results.length,
          itemsPerPage: results.length,
          currentPage: 1,
        });
      },
      errorMessage: "Error getting broadcasts",
      onError: (err) => {
        console.log(err);
      },
      onFinally: () => setLoading(false),
    });
  };

  const confirmAction = (data) => {
    api.updateBroadcasts(data).handle({
      onSuccess: () => {
        getAllBroadcasts();
      },
      errorMessage: "Error updating broadcast",
      successMessage: "Broadcast updated successfully",
    });
  };

  useEffect(() => {
    getAllBroadcasts();
  }, []);

  return (
    <AdminLayout title={"Approve Broadcasts"}>
      <MDBox width={"100%"}>
        <MDBox>
          <DataTableSimple table={table} />
        </MDBox>
        <ConfirmModal
          handleClose={handleCloseConfirmation}
          open={openConfirmation}
          handleConfirm={handleConfirmAction}
          title={"Are you sure?"}
          subtitle={"Are you sure you want to proceed?"}
        />
      </MDBox>
    </AdminLayout>
  );
}

export default AdminBroadcasts;
