import pxToRem from "../../assets/theme/functions/pxToRem";
import * as Yup from "yup";

export const dataTableModel = {
  columns: [
    {Header: "Title", accessor: "title", width: `${pxToRem(100)}`},
    {Header: "Description", accessor: "description", width: `${pxToRem(120)}`},
    {Header: "Status", accessor: "status", width: `${pxToRem(120)}`},
    {Header: "Sent to", accessor: "sent_to", width: `${pxToRem(120)}`},
    {Header: "Action", accessor: "action", width: `${pxToRem(50)}`},
  ],
  rows: [],
};
export const validationSchema = Yup.object().shape({
  from_user: Yup.string(),
  title: Yup.string(),
  description: Yup.string(),
  timestamp: Yup.mixed(),
  sent: Yup.boolean(),
})
export const initialValues = (data) => {
  return {
    from_user: data?.from_user ? data?.from_user : '',
    title: data?.title ? data?.title : '',
    description: data?.description ? data?.description : '',
    timestamp: data?.timestamp ? data?.timestamp : '',
    sent: data?.sent ? data?.sent : false,
  }
}
