import MDBox from "../../components/MDBox";
import {Form, Formik} from "formik";
import MDTypography from "../../components/MDTypography";
import Autocomplete from "@mui/material/Autocomplete";
import MDInput from "../../components/MDInput";
import FormikInput from "../../components/FormikInput";
import MDButton from "../../components/MDButton";
import {Modal} from "@mui/material";
import {useEffect, useRef, useState} from "react";
import * as Yup from "yup";


const validationSchema = Yup.object().shape({
  flag: Yup.string(),
})


function FlagModal({handleClose, open, data, handleConfirm}) {
  const formikRef = useRef();

  const initialValues = {
    content: data?.content ?? '',
    id: data?.id ?? '',
  };


  return (
    <Modal open={open} onClose={handleClose}>
      <MDBox sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        backgroundColor: '#282828',
        borderRadius: '10px',
        transform: 'translate(-50%, -50%)',
        width: 400,
        boxShadow: 24,
      }}>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange={false}
          onSubmit={values => {
            console.log('values: ', values)
            handleConfirm(values)
          }}
        >
          {(formik) => {
            console.log('formik values: ', formik.values);
            return (
              <MDBox>
                <MDTypography pt={2}
                              sx={{textAlign: 'center'}}>User flag</MDTypography>
                <MDBox p={3}>
                  <Form>
                    <MDBox>
                    </MDBox>
                    <FormikInput type={"textarea"} name={'content'} label={'Flag Note'} errors={formik.errors}/>
                    <MDBox display={'flex'} justifyContent={'center'} alignItems={'center'} gap={2} pt={2}>
                      <MDButton color={'primary'} onClick={() => {
                        formik.handleSubmit()
                      }}>Submit</MDButton>
                      <MDButton variant={'outlined'} onClick={() => {
                        console.log(data)
                        handleClose()
                      }}>Cancel</MDButton>
                    </MDBox>
                  </Form>
                </MDBox>
              </MDBox>
            )
          }}
        </Formik>
      </MDBox>
    </Modal>
  )
}

export default FlagModal
