import { Avatar, Checkbox } from "@mui/material";
import avatar from "../../assets/images/Ellipse.png";
import pxToRem from "../../assets/theme/functions/pxToRem";
import ActionsMenu from "../../components/ActionsMenu";
import Eye from "../../assets/icons/Eye.svg";
import editPen from "../../assets/icons/editPen.svg";
import trashRed from "../../assets/icons/trash_red.svg";
import warningCircle from "../../assets/icons/warningCircle.svg";

export const dataTableModel = {
  columns: [
    { Header: "Action", accessor: "action", disableOrdering: true, width: `${pxToRem(50)}` },
    { Header: "Profile", accessor: "profile", disableOrdering: true, width: `${pxToRem(80)}` },
    { Header: "First Name", accessor: "first_name", width: `${pxToRem(100)}` },
    { Header: "Last Name", accessor: "last_name", width: `${pxToRem(100)}` },
    { Header: "Email", accessor: "email", width: `${pxToRem(200)}` },
  ],
  rows: [],
};

const popOver = (item, checkUser) => {
  return (
    <Checkbox
      // checked={checkedUsers?.[item.id] || false}
      onChange={(e) => checkUser(item.id, e.target.checked)}
    // name="test"
    />
  )
}

export const renderTableRow = (item, checkUser) => {
  item.profile = <Avatar src={item?.profile_picture} alt={avatar} />
  item.first_name = item.first_name ?? 'No data'
  item.last_name = item.last_name ?? 'No data'
  item.role = item.is_admin ? 'Admin' : 'Marketing Agent'
  item.email = item.email ?? 'No data'
  item.phone_number = item.phone_number ?? 'No data'
  item.action = (popOver(item, checkUser))
  return item
}
