import {useApi} from "services/helpers";
import AdminLayout from "../../components/AdminLayout";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import {useEffect, useState} from "react";
import WalletCard from "./WalletCard";

function AdminWallet() {
  const api = useApi();
  const [loading, setLoading] = useState(false);
  const [points, setPoints] = useState(null);

  const getPointsBalance = () => {
    setLoading(true);
    api.getPointsBalance().handle({
      onSuccess: (result) => {
        setPoints(result.data);
      },
      errorMessage: "Sorry an error occurred",
      onFinally: () => setLoading(false),
    });
  };

  useEffect(() => {
    getPointsBalance();
  }, []);

  return (
    <AdminLayout title={"Wallet"}>
      <MDBox width={"100%"}>
        <MDTypography variant={"h5"} pt={4} pb={2}>
          Wallet
        </MDTypography>
        <MDBox display={"flex"} gap={4} flexWrap={"wrap"}>
          {points && (
            <>
              <WalletCard key="card-points" title="points" details={points} />
              <WalletCard key="card-usd" title="usd" details={points} />
            </>
          )}
        </MDBox>
      </MDBox>
    </AdminLayout>
  );
}

export default AdminWallet;
