import MDBox from "../../components/MDBox";
import {Form, Formik} from "formik";
import MDTypography from "../../components/MDTypography";
import Autocomplete from "@mui/material/Autocomplete";
import MDInput from "../../components/MDInput";
import FormikInput from "../../components/FormikInput";
import MDButton from "../../components/MDButton";
import {Modal} from "@mui/material";
import {useEffect, useRef, useState} from "react";
import * as Yup from "yup";


const validationSchema = Yup.object().shape({
  first_name: Yup.string(),
  last_name: Yup.string(),
  email: Yup.string().email(),
  role: Yup.string(),
})


function ViewDetailsModal({handleClose, open, data, editMode = false, handleConfirmEdit}) {
  const formikRef = useRef();

  const initialValues = {
    first_name: data?.first_name ?? '',
    last_name: data?.last_name ?? '',
    email: data?.email ?? '',
    state: data?.state ?? '',
    city: data?.city ?? '',
    address_line_1: data?.address_line_1 ?? '',
    address_line_2: data?.address_line_2 ?? '',
    zip_code: data?.zip_code ?? '',
    phone_number: data?.phone_number ?? '',
    role: data?.is_admin ? 'Admin' : 'Marketing Agent',
  };


  return (
    <Modal open={open} onClose={handleClose}>
      <MDBox sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        backgroundColor: '#282828',
        borderRadius: '10px',
        transform: 'translate(-50%, -50%)',
        width:{xs:'90%', md:'auto'},
        boxShadow: 24,
      }}>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange={false}
          onSubmit={values => {
            handleConfirmEdit(values)
          }}
        >
          {(formik) => {
            return (
              <MDBox>
                <MDTypography pt={2}
                              sx={{textAlign: 'center'}}>{editMode ? 'Edit User Data' : 'User Data'}</MDTypography>
                <MDBox p={3}>
                  <Form>
                    <MDBox>
                    </MDBox>
                    <FormikInput disabled={!editMode} name={'first_name'} label={'First Name'} errors={formik.errors}/>
                    <FormikInput disabled={!editMode} name={'last_name'} label={'Last Name'} errors={formik.errors}/>
                    <FormikInput disabled={!editMode} name={'email'} label={'Email'} errors={formik.errors}/>
                    <FormikInput disabled={true} name={'role'} label={'Role'} errors={formik.errors}/>
                    {data.role === 'Admin' &&
                      <>
                        <FormikInput disabled={!editMode} name={'state'} label={'State'} errors={formik.errors}/>
                        <FormikInput disabled={!editMode} name={'city'} label={'City'} errors={formik.errors}/>
                        <FormikInput disabled={!editMode} name={'address_line_1'} label={'Address Line 1'}
                                     errors={formik.errors}/>
                        <FormikInput disabled={!editMode} name={'address_line_2'} label={'Address Line 2'}
                                     errors={formik.errors}/>
                        <FormikInput disabled={!editMode} name={'zip_code'} label={'Zip'} errors={formik.errors}/>
                        <FormikInput disabled={!editMode} name={'phone_number'} label={'Phone Number'}
                                     errors={formik.errors}/>
                      </>
                    }
                    <MDBox display={'flex'} justifyContent={'center'} alignItems={'center'} gap={2} pt={2}>
                      {editMode &&
                        <MDButton color={'primary'} onClick={() => {
                          formik.handleSubmit()
                        }}>Submit</MDButton>
                      }
                      <MDButton variant={'outlined'} onClick={() => {
                        console.log(data)
                        handleClose()
                      }}>Cancel</MDButton>
                    </MDBox>
                  </Form>
                </MDBox>
              </MDBox>
            )
          }}
        </Formik>
      </MDBox>
    </Modal>
  )
}

export default ViewDetailsModal
