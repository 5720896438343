import AdminLayout from "../../components/AdminLayout";
import MDBox from "../../components/MDBox";
import React, {useEffect, useState} from "react";
import {useApi} from "../../services/helpers";
import MDButton from "../../components/MDButton";
import DataTableSimple from "../../components/DataTableSimple";
import SearchBar from "../../components/SearchBar";
import ActionsMenu from "../../components/ActionsMenu";
import send from "../../assets/icons/PaperPlaneTilt.svg";
import Eye from "../../assets/icons/Eye.svg";
import editPen from "../../assets/icons/editPen.svg";
import trashRed from "../../assets/icons/trash_red.svg";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";
import {dataTableModel} from "./constants";
import {CreateNotificationModal} from "./CreateNotificationModal";
import {NotificationDetailModal} from "./NotificationDetailModal";
import {renderTableRow} from "../users/utils";

export const AdminNotifications = () => {
  const api = useApi();
  const [loading, setLoading] = useState(false);

  const [table, setTable] = useState(dataTableModel);
  const [searchQuery, setSearchQuery] = useState("");
  const [paginationData, setPaginationData] = useState({
    counts: 0,
    itemsPerPage: 0,
    currentPage: 1,
  });
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [openConfirmSend, setOpenConfirmSend] = useState(false);
  const [notification, setNotification] = useState(null);
  const [isViewDetails, setIsViewDetails] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [openNotificationModal, setOpenNotificationModal] = useState(false);
  const [openNotificationDetailModal, setOpenNotificationDetailModal] = useState(false);

  const [notificationId, setNotificationId] = useState(null);

  const handleCloseDetail = () => {
    setOpenNotificationDetailModal(false);
  };

  const handleCloseSend = () => {
    setOpenConfirmSend(false);
  };
  const handleConfirmSend = () => {
    setOpenConfirmSend(false);
    sendNotification(notificationId);
  };

  const handleCloseDelete = () => {
    setOpenConfirmDelete(false);
  };
  const handleConfirmDelete = () => {
    setOpenConfirmDelete(false);
    deleteNotification(notificationId);
  };
  const handleCloseNotification = () => {
    setOpenNotificationModal(false);
  };
  const handleSubmitNotification = (data) => {
    setOpenNotificationModal(false);
    isEdit ? updateNotification(data, notificationId) : createNotification(data);
  };

  const getNotifications = (search = "", page = 1, type = "", ordering = "", page_size = 25) => {
    setLoading(true);
    api.getNotificationsAdmin({search, page, ordering, page_size}).handle({
      onSuccess: (result) => {
        const {data} = result;
        const DATA = data?.results?.map((d) => {
          let sent_to = "All Users";
          if (d.targets?.[0]) {
            d.targets[0].full_name = d.targets[0].full_name.trim(); // trim the full name to avoid false positive on if checking
            sent_to = d.targets?.[0]?.full_name || d.targets?.[0]?.email;
          }

          let menuList = [
            {
              title: "Send now",
              src: send,
              onClick: () => {
                setNotification(d);
                setNotificationId(d.id);
                setIsViewDetails(false);
                setIsEdit(false);
                setOpenConfirmSend(true);
              },
            },
            {
              title: "View Details",
              src: Eye,
              onClick: () => {
                setNotification(d);
                setIsViewDetails(true);
                setIsEdit(false);
                setOpenNotificationDetailModal(true);
              },
            },
            {
              title: "Edit",
              src: editPen,
              onClick: () => {
                setNotification(d);
                setNotificationId(d.id);
                setIsViewDetails(false);
                setIsEdit(true);
                setOpenNotificationModal(true);
              },
            },
            {
              title: "Delete",
              src: trashRed,
              onClick: () => {
                setOpenConfirmDelete(true);
                setNotificationId(d.id);
              },
            },
          ];

          if (d.sent) {
            menuList = menuList.filter((menu) => menu.title.includes("View"));
          }

          return {
            title: d.title ?? "No data",
            description: d.description ?? "No data",
            status: d.sent ? "Sent" : "Pending",
            sent_to,
            action: (
              <>
                <ActionsMenu menuContent={menuList} />
              </>
            ),
          };
        });
        setTable({...dataTableModel, rows: DATA});
        setPaginationData({counts: data?.length, itemsPerPage: data?.length, currentPage: 1});
        setLoading(false);
      },
      errorMessage: "Sorry an error occurred",
      onError: (err) => {
        console.log(err);
      },
      onFinally: () => setLoading(false),
    });
  };

  const sendNotification = (id) => {
    api.sendNotification(id).handle({
      onSuccess: () => {
        getNotifications();
      },
      errorMessage: "Sorry an error occurred",
      successMessage: "Notification sent successfully",
    });
  };
  const createNotification = (data) => {
    api.createNotification(data).handle({
      onSuccess: () => {
        getNotifications();
      },
      errorMessage: "Sorry an error occurred",
      successMessage: "Notification created successfully",
    });
  };

  const updateNotification = (data, id) => {
    api.updateNotification(data, id).handle({
      onSuccess: () => {
        getNotifications();
      },
      errorMessage: "Sorry an error occurred",
      successMessage: "Notification updated successfully",
    });
  };

  const deleteNotification = (id) => {
    api.deleteNotification(id).handle({
      onSuccess: () => {
        setNotificationId(null);
        getNotifications();
      },
      errorMessage: "Sorry an error occurred",
      successMessage: "Notification deleted successfully",
    });
  };

  useEffect(() => {
    getNotifications();
  }, []);

  return (
    <AdminLayout title={"Notifications"}>
      <MDBox width={"100%"}>
        <MDBox display={"flex"} justifyContent={"space-between"}></MDBox>
        <MDBox
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          mb={"10px"}
        >
          <MDBox display={"flex"} flexDirection={"row"} alignItems={"center"}>
            <SearchBar
              loading={loading}
              search={getNotifications}
              setSearchQuery={setSearchQuery}
            />
            <MDBox></MDBox>
          </MDBox>
          <MDButton
            color={"primary"}
            sx={{height: "44px", borderRadius: "10px"}}
            onClick={() => {
              setOpenNotificationModal(true);
              setIsEdit(false);
              setIsViewDetails(false);
            }}
          >
            Create New Notification
          </MDButton>
        </MDBox>
        <MDBox>
          <DataTableSimple table={table} />
        </MDBox>
        <ConfirmModal
          handleClose={handleCloseDelete}
          open={openConfirmDelete}
          handleConfirm={handleConfirmDelete}
          title={"Are you sure?"}
          subtitle={"Are you sure you want to delete this notification?"}
        />
        <ConfirmModal
          handleClose={handleCloseSend}
          open={openConfirmSend}
          handleConfirm={handleConfirmSend}
          title={"Are you sure?"}
          subtitle={"Are you sure you want to send this notification?"}
        />
        <CreateNotificationModal
          open={openNotificationModal}
          isEdit={isEdit}
          data={notification}
          handleClose={handleCloseNotification}
          handleSubmit={handleSubmitNotification}
        />
        <NotificationDetailModal
          open={openNotificationDetailModal}
          handleClose={handleCloseDetail}
          data={notification}
        />
      </MDBox>
    </AdminLayout>
  );
};
