// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
// @mui material components
import Card from "@mui/material/Card";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import {formatNumberToCurrency} from "services/helpers";
import colors from "assets/theme/base/colors";

function snakeCaseToCapital(str) {
  return str
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

const WalletCard = ({color, title, details}) => {
  const lastMonth = details.last_month[`total_${title}`];
  const currentMonth = details.last_month[`total_${title}`];
  const total = details[`total_${title}`];

  const RowCard = ({label, amount}) => {
    return (
      <MDBox
        borderRadius={"10px 10px 0px 0px"}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        pt={1}
        px={2}
        height="5rem"
      >
        <MDTypography
          component="p"
          variant="button"
          color="text"
          display="flex"
          flexDirection="column"
          mt={1}
        >
          <MDTypography component="span" variant="100" color="primary" fontWeight="500">
            {snakeCaseToCapital(label)}
          </MDTypography>
          <MDTypography
            component="span"
            fontWeight="bold"
            variant={label === "total" ? "h2" : "h4"}
            color={"light"}
          >
            {title === "usd" ? formatNumberToCurrency(amount) : amount}
          </MDTypography>
        </MDTypography>
      </MDBox>
    );
  };

  return (
    <Card sx={{minWidth: {xs: "255px"}, backgroundColor: `#282828`}}>
      <MDBox
        borderRadius={"10px 10px 0px 0px"}
        bgColor={color}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        pt={1}
        px={3}
        height="5rem"
      >
        <MDBox textAlign="right">
          <MDTypography color={"light"} variant="h4">
            {snakeCaseToCapital(title)}
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox pb={2} px={1} mt={3}>
        <RowCard key="lastMonth" label={"last_month"} amount={lastMonth || 0} />
        <RowCard key="currentMonth" label={"current_month"} amount={currentMonth || 0} />
        <RowCard key="total" label={"total"} amount={total || 0} />
      </MDBox>
    </Card>
  );
};

// Setting default values for the props of WalletCard
WalletCard.defaultProps = {
  color: "info",
};
// Typechecking props for the WalletCard
WalletCard.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  details: PropTypes.shape({
    total_points: PropTypes.number,
    total_usd: PropTypes.number,
  }),
};
export default WalletCard;
