import MDBox from "components/MDBox";
import {CircularProgress, Input} from "@mui/material";
import {Search} from "@mui/icons-material";
import TextField from "@mui/material/TextField";
import {styled} from "@mui/material/styles";
import pxToRem from "../../assets/theme/functions/pxToRem";
import debounce from "lodash.debounce";

const CssTextField = styled(TextField)({
  '.MuiInputBase-root': {
    display: 'flex',
    width:"100%",
    // minWidth: '100%',
    maxWidth: '800px',
    height: "44px",
    borderRadius: "20px",
    backgroundColor: "#282828",
    color: "#fff",
    fontSize: "14px",
  },
  '& label.Mui-focused': {
    color: '#fff',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#282828',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#282828',
    },
    '&:hover fieldset': {
      borderColor: '#282828',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#282828',
    },
  },
  '.MuiOutlinedInput-input': {
    color: "#fff"
  }
});

const SearchBar = ({loading, setSearchQuery}) => {
  const delayedSearch = debounce(setSearchQuery, 300);

  const searchFunc = (text = '') => {
    delayedSearch(text)
  }

  return (
      <MDBox mr={3} sx={{width:"100%", maxWidth: '480px',}} >
      <CssTextField
        fullWidth
        placeholder="Search"
        type="text"
        onChange={(evt) => {
          searchFunc(evt?.target?.value)}}
        size="small"
        InputProps={{
          endAdornment: loading === false ? <Search/> : <CircularProgress size={14} color="primary"/>,
        }}
      />
    </MDBox>
  )

}

export default SearchBar;
