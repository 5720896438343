import AdminLayout from "../../components/AdminLayout";
import MDBox from "../../components/MDBox";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { useApi } from "../../services/helpers";
import MDInput from "../../components/MDInput";
import MDButton from "../../components/MDButton";
import SearchBar from "../../components/SearchBar";
import Autocomplete from "@mui/material/Autocomplete";
import ViewDetailsModal from "./ViewDetailsModal";
import CreateAdminModal from "./CreateAdminModal";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";
import FlagModal from "./FlagModal";
import { dataTableModel, renderTableRow } from "./utils";
import DataTable from "../../components/DataTable";
import {
  usePlaidLink,
  PlaidLinkOptions,
  PlaidLinkOnSuccess,
} from 'react-plaid-link';
import {Grid} from "@mui/material";

export const validationSchema = Yup.object().shape({
  project_name: Yup.mixed(),
  project_address: Yup.mixed(),
  company: Yup.mixed(),
  region: Yup.mixed(),
  brand: Yup.mixed(),
  project_manager: Yup.mixed(),
  from_date: Yup.string(),
  to_date: Yup.string(),
})

export const initialValues = {
  company: null,
  region: null,
  brand: null,
  project_manager: null,
  order: null,
  status: null,
  manufacturer: null,
  product: null,
  from_date: '',
  to_date: '',
};

function AdminUsers() {
  const api = useApi()
  const [loading, setLoading] = useState(false);
  const [datatable, setDatatable] = useState({ ...dataTableModel })
  const [searchQuery, setSearchQuery] = useState('');
  const [paginationData, setPaginationData] = useState({
    counts: 0,
    itemsPerPage: 0,
    currentPage: 1,
    ordering: ""
  });
  const values = [
    { value: 1, label: 'All' },
    { value: 2, label: 'Admin' },
    { value: 3, label: 'Marketing Agent' }
  ];
  const [value, setValue] = useState(values[0]);
  const [openView, setOpenView] = useState(false);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [openConfirmCreateAdmin, setOpenConfirmCreateAdmin] = useState(false);
  const [openFlagInput, setOpenFlagInput] = useState(false);
  const [openCreateAdmin, setOpenCreateAdmin] = useState(false);
  const [createAdminValues, setCreateAdminValues] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [dataView, setDataView] = useState(null)
  const [editMode, setEditMode] = useState(false)
  const [userId, setUserId] = useState(null)
  const [typeUser, setTypeUser] = useState("")

  const handleCloseView = () => {
    setOpenView(false)
    setDataView(null)
  }
  const handleConfirmEdit = (data) => {
    sendEdit(data)
    setOpenView(false)
    setUserId(null)
  }
  const handleCloseDelete = () => {
    setOpenConfirmDelete(false)
  }
  const handleConfirmDelete = () => {
    setOpenConfirmDelete(false)
    confirmDelete(userId)
  }
  const handleCreateAdmin = () => {
    setOpenConfirmCreateAdmin(false)
    createUserAdmin(createAdminValues)
  }
  const handleCloseConfirmCreateAdmin = () => {
    setOpenConfirmCreateAdmin(false)
    setOpenCreateAdmin(false)

  }
  const handleCloseCreateAdmin = () => {
    setOpenCreateAdmin(false)
  }
  const handleSubmitCreateAdmin = (values) => {
    setOpenConfirmCreateAdmin(true)
    setCreateAdminValues(values)
    setOpenCreateAdmin(false)
  }
  const handleConfirmFlag = (values) => {
    setOpenFlagInput(false)
    sendFlag(values)
  }
  const handleCloseFlag = () => {
    setOpenFlagInput(false)
    setDataView(null)
  }

  const getAllUsers = (search, page = 1, ordering = "") => {
    setLoading(true)
    api.getAllUsers({ search, page, page_size: pageSize, type: typeUser, ordering }).handle({
      onSuccess: (result) => {
        const { count, end, start, results } = result?.data
        const tmp = { ...dataTableModel }
        tmp.rows = results.map(e => renderTableRow(e, setOpenView, setEditMode, setDataView, setOpenConfirmDelete, setUserId, setOpenFlagInput))
        setDatatable(tmp)
        setPaginationData(value => ({
          ...value,
          counts: count,
          start,
          end,
          itemsPerPage: results?.length,
          ordering
        }))
      },
      errorMessage: 'Error getting users',
      onError: err => {
        console.log(err)
      },
      onFinally: () => setLoading(false)
    }
    )
  }


  const confirmDelete = (id) => {
    api.deleteUser(id).handle({
      onSuccess: () => {
        getAllUsers();
      },
      errorMessage: "Sorry an error occurred",
      successMessage: "User deleted successfully",
    });
  }

  const sendEdit = (data) => {
    api.editUser(data, userId).handle({
      onSuccess: () => {
        getAllUsers();
      },
      errorMessage: "Sorry an error occurred",
      successMessage: "User data modified successfully",
    });
  }

  const sendFlag = (data) => {
    api.sendFlag(data).handle({
      onSuccess: () => {
        setDataView(null)
        getAllUsers();
      },
      errorMessage: "Sorry an error occurred",
      successMessage: "User data modified successfully",
    });
  }

  const createUserAdmin = (data) => {
    api.createUserAdmin(data).handle({
      onSuccess: () => {
        getAllUsers();
      },
      errorMessage: "Sorry an error occurred",
      successMessage: "New Admin User Created Successfully",
    });
  }

  const getFilteredPage = () => {
    let filters = { search: searchQuery, page: 1 }
    setPaginationData(value => ({ ...value, currentPage: filters.page }))
    return filters
  }

  useEffect(() => {
    getAllUsers()
  }, [])

  useEffect(() => {
    const { search, page } = getFilteredPage()
    getAllUsers(search, page)
  }, [searchQuery, typeUser, pageSize])

  return (
    <AdminLayout
      title={'Users'}
    >
      <MDBox width={"100%"}>
        <MDBox display={"flex"} justifyContent={"space-between"}>
        </MDBox>
        <Grid container justifyContent={"space-between"} alignItems={"center"}
          mb={"10px"} gap={1} direction={{xs:'column', md:'row'}} >
            <Grid container item xs={12} md={8} columnSpacing={2}>
            <Grid item xs={6} md={6}>
              <SearchBar loading={loading} setSearchQuery={setSearchQuery} />
            </Grid>
              <Grid item  justifyContent={"end"} xs={5}>
              <Autocomplete
                disableClearable
                isOptionEqualToValue={(option, value) => option.id === value.id}
                value={value}
                options={values}
                onChange={(event, value) => {
                  setValue(value)
                  const type = value.label === 'All' ? '' : value.label === 'Admin' ? 'ADM' : 'MKT'
                  setTypeUser(type)
                  getAllUsers(searchQuery)
                }}
                size="small"
                sx={{
                  width: "60px",
                  height: "44px",
                }}
                renderInput={(params) => <MDInput {...params}
                  sx={{
                    width:'100%',
                    minWidth: "150px",
                    height: "44px",
                 // marginLeft: "12px",
                    borderRadius: "10px",
                    backgroundColor: "#282828",
                    color: "#fff",
                    fontSize: "14px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    alignItems: "center",
                    padding: "12px"
                  }}
                />}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} md={3} justifyContent={'end'}>
          <MDButton sx={{
            width: {xs:"100%", md:'200px'},
            height: "44px",
            backgroundColor: "#DAAD71",
            borderRadius: "10px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            fontFamily: "OpenSans",
            fontSize: "14px",
            fontWeight: "600px",
            color: "#090909"
          }}
            onClick={() => {
              setOpenCreateAdmin(true)
            }}
    >
            Create Admin
          </MDButton>
        </Grid>
        </Grid>
        <MDBox>
          <DataTable
            loading={loading}
            table={datatable}
            currentPage={paginationData.currentPage}
            numberOfItems={paginationData.counts}
            numberOfItemsPage={paginationData.itemsPerPage}
            start={paginationData.start}
            end={paginationData.end}
            searchFunc={getAllUsers}
            searchQuery={searchQuery}
            setPageSize={setPageSize}
            pageSize={pageSize}
            onPageChange={page => {
              const { search } = getFilteredPage()
              getAllUsers(search, page)
              setPaginationData(value => ({ ...value, currentPage: page }))
            }}
          />
        </MDBox>
        <ViewDetailsModal
          handleClose={handleCloseView}
          open={openView}
          data={dataView}
          editMode={editMode}
          handleConfirmEdit={handleConfirmEdit}
        />
        <ConfirmModal
          handleClose={handleCloseDelete}
          open={openConfirmDelete}
          handleConfirm={handleConfirmDelete}
          title={'Are you sure?'}
          subtitle={'Are you sure you want to delete this user?'}
        />
        <CreateAdminModal
          open={openCreateAdmin}
          handleClose={handleCloseCreateAdmin}
          handleSubmit={handleSubmitCreateAdmin}
        />
        <ConfirmModal
          handleClose={handleCloseConfirmCreateAdmin}
          open={openConfirmCreateAdmin}
          handleConfirm={handleCreateAdmin}
          title={'Are you sure?'}
          subtitle={'Are you sure you want to create a new admin user?'}
        />
        <FlagModal
          open={openFlagInput}
          data={dataView}
          handleClose={handleCloseFlag}
          handleConfirm={handleConfirmFlag}
        />
      </MDBox>
    </AdminLayout>
  );
}

export default AdminUsers;
