import {Avatar} from "@mui/material";
import avatar from "../../assets/images/Ellipse.png";
import ActionsMenu from "../../components/ActionsMenu";
import info from "../../assets/icons/Info.svg";
import checked from "../../assets/icons/checked.svg";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import React from "react";


export const dataTableModel = {
  columns: [
    {Header: "Profile", accessor: "profile", width: 200,  disableOrdering: true},
    {Header: "Redeem Points", accessor: "redeem_points", disableOrdering: true},
    {Header: "Available Points", accessor: "available_points",  disableOrdering: true},
    {Header: "Bank Name", accessor: "bank_name",  disableOrdering: true},
    {Header: "Account Holder", accessor: "account_holder",  disableOrdering: true},
    {Header: "Account Number", accessor: "account_number",  disableOrdering: true},
    {Header: "Routing Number", accessor: "routing_number",  disableOrdering: true},
    {Header: "Social Security Number", accessor: "social_security_number",  disableOrdering: true},
    {Header: "Status", accessor: "status",  disableOrdering: true},
    {Header: "Action", accessor: "action", disableOrdering: true},
  ],
  rows: [],
};

const popOver = (item, setItemId, setOpenFlagInput, setDataView, setOpenDeny) => {
  return (
    <>
      <ActionsMenu
        iconStyles={{ width: 20, height: 20 }}
        menuContent={
            [
              {
                title: 'Approve',
                src: checked,
                onClick: () => {
                  setItemId(item.id)
                  setDataView(item)
                  setOpenFlagInput(true)
                }
              },
              {
                title: 'Deny',
                src: info,
                onClick: () => {
                  setItemId(item.id)
                  setOpenDeny(true)
                }
              },
            ]
        }
      />
    </>
  )
}

const actionRender = (item, setOpenView, setEditMode, setDataView, setOpenConfirmDelete, setUserId, setOpenFlagInput) => {
  if(item.status === 'APPROVED'){
    return <MDBox sx={{marginLeft: 2 }} component={"img"} src={checked}/>
  } else if (item.status === 'DENIED') {
    return <MDBox sx={{marginLeft: 2, width: 28, height: 28 }} component={"img"} src={info}/>
  } else {
    return popOver(item, setOpenView, setEditMode, setDataView, setOpenConfirmDelete, setUserId, setOpenFlagInput)
  }
}


export const renderTableRow = (item, setOpenView, setEditMode, setDataView, setOpenConfirmDelete, setUserId, setOpenFlagInput) => {
  item.profile =  <MDBox display={'flex'} alignItems={'center'} gap={1} >
    <Avatar src={item.created_by?.profile_picture} alt={avatar}/>
    <MDTypography variant={'subtitle2'}>{item.created_by?.first_name} {item.created_by?.last_name ?? 'No data'}</MDTypography>
  </MDBox>
  item.redeem_points = item.redeem_points ?? 'No data'
  item.available_points= item.created_by?.total_points ?? 'No data'
  item.status= item.status ?? 'No data'
  item.bank_name= item.bank_name ?? 'No data'
  item.account_holder= item.account_holder ?? 'No data'
  item.account_number= item.account_number ?? 'No data'
  item.routing_number= item.routing_number ?? 'No data'
  item.account_holder = item?.created_by?.payment_info?.[0]?.account_holder || 'No data'
  item.account_number = item?.created_by?.payment_info?.[0]?.account_number || 'No data'
  item.routing_number = item?.created_by?.payment_info?.[0]?.routing_number || 'No data'
  item.social_security_number = item?.created_by?.payment_info?.[0]?.ssn || 'No data'
  item.social_security_number= item.social_security_number ?? 'No data'
  item.action = actionRender(item, setOpenView, setEditMode, setDataView, setOpenConfirmDelete, setUserId, setOpenFlagInput)
  return item
}
