import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import React, {useRef, useState} from "react";
import MDBox from "../MDBox";
import dots from "../../assets/icons/dots.svg"
import MDTypography from "../MDTypography";
import {color} from "chart.js/helpers";
import colors from "../../assets/theme/base/colors";

function ActionsMenu({
                       icon,
                       menuContent,
                       iconStyles,
                     }) {
  const buttonRef = useRef(null);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false)
  };


  return (
    <div>
      <IconButton
        ref={buttonRef}
        id="positioned-demo-button"
        aria-controls={'positioned-demo-menu'}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="outlined"
        color="neutral"
        onClick={() => {
          setOpen(!open);
        }}
      >
        {icon ?
          icon
          :
          <MDBox sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",

          }}>
            <MDBox component={"img"} src={dots} />
          </MDBox>
        }
      </IconButton>
      <Menu
        id="positioned-demo-menu"
        anchorEl={buttonRef.current}
        open={open}
        onClose={handleClose}
        aria-labelledby="positioned-demo-button"
        placement="bottom-end"
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.6))',
            mt: 1.5,
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 19,
              width: 10,
              height: 10,
              backgroundColor: "#282828 !important",
              transform: 'translateY(-50%) rotate(45deg) scale(2)',
              zIndex: 0,
            },
          },
        }}
        sx={{
          ".MuiPopover-paper": {
            padding: "12px 18px",
            borderRadius: "10px",
            backgroundColor: "#282828 !important",
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
          }
        }}
      >
        {menuContent?.map((items, index) => (
          <MenuItem key={`id-${index}`} onClick={() => {items.onClick(); handleClose()}}>
            {items?.src && <MDBox component={"img"} src={items.src} sx={iconStyles}/>}
            <MDTypography sx={{
              color: "#fff",
              fontSize: "13px",
              fontFamily: "OpenSans",
              fontWeight: 500,
              marginLeft: "10px",
            }}>{items.title}</MDTypography>
          </MenuItem>
          ))
        }
      </Menu>
    </div>
  );
}

export default ActionsMenu
