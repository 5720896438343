import React, {useEffect} from 'react';
import {setLayout, useMaterialUIController} from "../../context";
import {useLocation} from "react-router-dom";
import PageLayout from "../../components/PageLayout";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";


const NotFound = () => {
  const [, dispatch] = useMaterialUIController();
  const { pathname } = useLocation();
  useEffect(() => {
    setLayout(dispatch, "page");
  }, [pathname]);

  return <PageLayout>
    <MDBox style={{
    height: '100vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
  }} bgColor={'black'}>
    <MDTypography variant={'h1'} color={'primary'}>404 Page not found</MDTypography>
  </MDBox>
  </PageLayout>
};

export default NotFound;
