import AdminLayout from "../../components/AdminLayout";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import * as Yup from "yup";
import {useEffect, useRef, useState} from "react";
import {showMessage, transformObjectsToId, useApi} from "../../services/helpers";

export const validationSchema = Yup.object().shape({
  project_name: Yup.mixed(),
  project_address: Yup.mixed(),
  company: Yup.mixed(),
  region: Yup.mixed(),
  brand: Yup.mixed(),
  project_manager: Yup.mixed(),
  from_date: Yup.string(),
  to_date: Yup.string(),
});

export const initialValues = {
  company: null,
  region: null,
  brand: null,
  project_manager: null,
  order: null,
  status: null,
  manufacturer: null,
  product: null,
  from_date: "",
  to_date: "",
};

function AdminAnalytics() {
  const api = useApi();
  const [loading, setLoading] = useState(false);
  const [brands, setBrands] = useState([]);
  const [regions, setRegions] = useState([]);
  const [managers, setManagers] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [orders, setOrders] = useState([]);
  const [status, setStatus] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const [products, setProducts] = useState([]);

  return (
    <AdminLayout title={"Analytics"}>
      <MDBox width={"100%"}>
        <MDBox display={"flex"} justifyContent={"space-between"}></MDBox>
        <MDTypography variant={"h5"} pt={4} pb={2}>
          View Total Sales
        </MDTypography>
      </MDBox>
    </AdminLayout>
  );
}

export default AdminAnalytics;
