import pxToRem from "../../assets/theme/functions/pxToRem";
import * as Yup from "yup";
// import getLanguages from "../sign-in/index"
import {Api} from "../../services/api"
import {runInAction} from "mobx";

export const dataTableModel = {
  columns: [
    {Header: "Type", accessor: "type", width: `${pxToRem(100)}`},
    {Header: "Title", accessor: "title", width: `${pxToRem(100)}`},
    {Header: "Description", accessor: "description", width: `${pxToRem(120)}`},
    {Header: "Video", accessor: "video", width: `${pxToRem(200)}`, maxWidth: 200},
    {Header: "Thumbnail", accessor: "thumbnail", width: `${pxToRem(150)}`},
    {Header: "Language", accessor: "language", width: `${pxToRem(150)}`},
    {Header: "Action", accessor: "action", width: `${pxToRem(50)}`},
  ],
  rows: [],
};

const validationSchemaBase = {
  type: Yup.mixed(),
  title: Yup.string().required('Title is required'),
  language: Yup.mixed(),
}
export const validationSchemas = {
  FAQ: Yup.object().shape({
    ...validationSchemaBase,
    description: Yup.string(),
  }),

  VIDEO: Yup.object().shape({
    ...validationSchemaBase,
    video: Yup.mixed(),
    thumbnail: Yup.mixed(),
    duration: Yup.mixed().required('Duration is required'),
  }),

  TRAINING: Yup.object().shape({
    ...validationSchemaBase,
    duration: Yup.mixed().required('Duration is required'),
    quizzes: Yup.array().of(Yup.object({
      title: Yup.string().required('Title is required'),
      show_on: Yup.mixed().required('Show on is required'),
      options: Yup.array().of(Yup.object({
        option: Yup.string(),
        is_correct: Yup.boolean(),
      })).required('Options are required').nullable(),
    })).required('Quizzes are required').nullable(),
  })
}

export const QUIZZES = (data) => {
  return {
    title: data?.title ? data?.title : '',
    show_on: data?.show_on ? data?.show_on : '',
    options: data?.options.map(item => {
      return {
        option: item.option,
        is_correct: item.is_correct
      }
    }) || []
  }
}
export const initialValues = (data) => {
  return {
    type: data?.type ? {value: data.type, label: data.type} : {value: null, label: ""},
    title: data?.title ? data.title : '',
    language: data?.language ? data?.language : {code: '', name: ''},
    description: data?.description ? data.description : '',
    video: data?.video ? data.video : null,
    duration: data?.duration ? data?.duration : '',
    thumbnail: data?.thumbnail ? data.thumbnail : null,
    quizzes: data?.quizzes.map(item => QUIZZES(item)) || [QUIZZES()],
  }
}
export const typeValues = [
  {value: "FAQ", label: "FAQ"},
  {value: "VIDEO", label: "VIDEO"},
  {value: "TRAINING", label: "TRAINING"}
];
export const filterValues = [{value: "", label: "All"}, ...typeValues]
