import AdminLayout from "../../components/AdminLayout";
import MDButton from "../../components/MDButton";
import React, {useEffect, useRef, useState} from "react";
import MDBox from "../../components/MDBox";
import {Form, Formik} from "formik";
import FormikInput from "../../components/FormikInput";
import {useApi} from "../../services/helpers";
import equal from 'assets/icons/equal.svg';
import MDTypography from "../../components/MDTypography";
import {initialValues, validationSchema} from "./utils";

export const AdminPointLegend = () => {
  const api = useApi()
  const formikRef = useRef();
  const [loading, setLoading] = useState(false);
  const [points, setPoints] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);

  const updatePointsLegend = (data) => {
    api.updatePointLegend(data).handle({
        successMessage: 'Points Legend successfully updated',
        errorMessage: 'Error updating Points Legend',
      }
    )
  }

  const getPointsLegend = () => {
    setLoading(true)
    api.getPointLegend().handle({
      onSuccess: (result) => {
        const { data } = result;
        setPoints(data[0])
      },
      errorMessage: 'Error getting points',
      onFinally: () => setLoading(false)
    })
  }

  useEffect(() => {
    getPointsLegend()
  }, [])


  return (
    <AdminLayout
      title={'Points Legend'}
    >
      <MDBox  width={{xs:'100%', md:'60%'}} display={'flex'} flexDirection={'column'}>
        <MDBox justifyContent={'flex-end'} width={'100%'} display={'flex'} gap={2} py={2}>
          {
            isDisabled ?
            <MDButton
              color={"primary"}
              sx={{ height: '32px', minHeight: '32px', width: '106px' }}
              onClick={() => setIsDisabled(false)}
            >
              Edit
            </MDButton>
              :
            <>
              <MDButton
                variant={'text'}
                sx={{ height: '32px', minHeight: '32px', width: '106px' }}
                onClick={() => setIsDisabled(true)}
              >
                Cancel
              </MDButton>
              <MDButton
                color={"primary"}
                sx={{ height: '32px', minHeight: '32px' }}
                onClick={() => formikRef?.current.handleSubmit()}
              >
                Save Changes
              </MDButton>
            </>
          }
        </MDBox>
        <MDBox>
          <Formik
            innerRef={formikRef}
            initialValues={initialValues(points)}
            validationSchema={validationSchema}
            validateOnBlur={false}
            enableReinitialize={true}
            onSubmit={values => {
              updatePointsLegend(values);
            }}
          >
            {(formik) => {
              return (
                <Form>
                  <MDBox display={'flex'} alignItems={'center'} gap={5} borderBottom={'1px solid #282828'} pb={2}>
                    <MDBox width={'100%'}>
                      <MDTypography variant={'h6'} pb={1}>Points</MDTypography>
                      <FormikInput name={'points'} label={'Points'} type={'number'} disabled={isDisabled} errors={formik.errors}/>
                    </MDBox>
                    <MDBox component={'img'} src={equal} alt={'equal'} sx={{ width: 30, height: 30, marginTop: 1 }} />
                    <MDBox width={'100%'}>
                      <MDTypography variant={'h6'} pb={1}>USD</MDTypography>
                      <FormikInput name={'usd'} label={'USD'}  type={'number'} disabled={isDisabled} errors={formik.errors}/>
                    </MDBox>
                  </MDBox>
                  <MDBox gap={5} py={2}>
                    <MDBox width={'100%'} pb={1}>
                      <MDTypography variant={'h6'} pb={1}>Leads</MDTypography>
                      <FormikInput name={'leads'} label={'You earn X Points for every lead you submit.'} type={'number'} disabled={isDisabled} errors={formik.errors}/>
                    </MDBox>
                    <MDBox width={'100%'} py={1}>
                      <MDTypography variant={'h6'} pb={1}>Qualified Leads</MDTypography>
                      <FormikInput name={'qualified_leads'} label={'Your Qualified Lead: X Points'} type={'number'} disabled={isDisabled} errors={formik.errors}/>
                    </MDBox>
                    <MDBox display={'flex'} gap={1}>
                      <MDBox width={'100%'} py={1}>
                        <MDTypography variant={'h6'} pb={1}>Level 2</MDTypography>
                        <FormikInput name={'level_2'} label={'Points'} type={'number'} disabled={isDisabled} errors={formik.errors}/>
                      </MDBox>
                      <MDBox width={'100%'} py={1}>
                        <MDTypography variant={'h6'} pb={1}>Level 3</MDTypography>
                        <FormikInput name={'level_3'} label={'Points'} type={'number'} disabled={isDisabled} errors={formik.errors}/>
                      </MDBox>
                      <MDBox width={'100%'} py={1}>
                        <MDTypography variant={'h6'} pb={1}>Level 4</MDTypography>
                        <FormikInput name={'level_4'} label={'Points'} type={'number'} disabled={isDisabled} errors={formik.errors}/>
                      </MDBox>
                      <MDBox width={'100%'} py={1}>
                        <MDTypography variant={'h6'} pb={1}>Level 5</MDTypography>
                        <FormikInput name={'level_5'} label={'Points'} type={'number'} disabled={isDisabled} errors={formik.errors}/>
                      </MDBox>
                    </MDBox>
                    <MDBox width={'100%'} py={1}>
                      <MDTypography variant={'h6'} pb={1}>Social Post</MDTypography>
                      <FormikInput name={'social_post'} label={'You earn X Points for every approved social post.'} type={'number'} disabled={isDisabled} errors={formik.errors}/>
                    </MDBox>
                    <MDBox width={'100%'} py={1}>
                      <MDTypography variant={'h6'} pb={1}>Minimum Points</MDTypography>
                      <FormikInput name={'minimum_points'} label={'Minimum point to get paid'} type={'number'} disabled={isDisabled} errors={formik.errors}/>
                    </MDBox>
                    <MDBox display={'flex'} gap={1}>
                      <MDBox width={'100%'} py={1}>
                        <MDTypography variant={'h6'} pb={1}>Text Reach</MDTypography>
                        <FormikInput name={'text_reach'} label={'Points'} type={'number'} disabled={isDisabled} errors={formik.errors}/>
                      </MDBox>
                      <MDBox width={'100%'} py={1}>
                        <MDTypography variant={'h6'} pb={1}>Text Reach</MDTypography>
                        <FormikInput name={'text_reach_recipients'} label={'Recipients'} type={'number'} disabled={isDisabled} errors={formik.errors}/>
                      </MDBox>
                      <MDBox width={'100%'} py={1}>
                        <MDTypography variant={'h6'} pb={1}>Text Reach</MDTypography>
                        <FormikInput name={'text_reach_limits'} label={'Limits'} type={'number'} disabled={isDisabled} errors={formik.errors}/>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </Form>
              )
            }}
          </Formik>
        </MDBox>
      </MDBox>
    </AdminLayout>
  )
}
