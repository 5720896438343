import MDBox from "../../components/MDBox";
import {Form, Formik} from "formik";
import MDTypography from "../../components/MDTypography";
import FormikInput from "../../components/FormikInput";
import MDButton from "../../components/MDButton";
import {Modal} from "@mui/material";
import {useRef} from "react";
import * as Yup from "yup";
import {date_fmt} from "services/helpers";

export const validationSchema = Yup.object().shape({
  title: Yup.string(),
  description: Yup.string(),
  timestamp: Yup.string(),
  sent: Yup.string(),
  target: Yup.string().optional(),
});
export const initialValues = (data) => {
  return {
    title: data?.title ? data?.title : "",
    description: data?.description ? data?.description : "",
    timestamp: data?.timestamp ? date_fmt(data?.timestamp) : "",
    sent: data?.sent ? data?.sent : false,
    target: data?.targets[0] ? data.targets[0].full_name || data.targets[0].email : "All Users",
  };
};

export const NotificationDetailModal = ({handleClose, open, data}) => {
  const formikRef = useRef();
  return (
    <Modal open={open} onClose={handleClose}>
      <MDBox
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          backgroundColor: "#282828",
          borderRadius: "10px",
          transform: "translate(-50%, -50%)",
          width: {xs: "90%", md: "600px"},
          boxShadow: 24,
        }}
      >
        <Formik
          innerRef={formikRef}
          initialValues={initialValues(data)}
          validationSchema={validationSchema}
          validateOnChange={false}
          onSubmit={(values) => {
            console.log("values: ", values);
          }}
        >
          {(formik) => {
            return (
              <MDBox>
                <MDTypography pt={2} sx={{textAlign: "center"}}>
                  Notification Details
                </MDTypography>
                <MDBox p={3}>
                  <Form>
                    <MDBox></MDBox>
                    <FormikInput
                      disabled={true}
                      mb={1}
                      name={"title"}
                      label={"Notification Title"}
                      errors={formik.errors}
                    />
                    <FormikInput
                      disabled={true}
                      mb={1}
                      name={"description"}
                      label={"Notification Message"}
                      errors={formik.errors}
                    />
                    <FormikInput
                      disabled={true}
                      mb={1}
                      name={"timestamp"}
                      label={"Notification Time"}
                      errors={formik.errors}
                    />
                    <FormikInput
                      disabled={true}
                      mb={1}
                      name={"target"}
                      label={"Sent to"}
                      errors={formik.errors}
                    />
                    <FormikInput
                      disabled={true}
                      name={"sent"}
                      label={"Sent"}
                      type={"checkbox"}
                      checked={formik.values?.sent}
                    />
                    <MDBox
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      gap={2}
                      pt={2}
                    >
                      <MDButton
                        variant={"outlined"}
                        onClick={() => {
                          handleClose();
                        }}
                      >
                        Cancel
                      </MDButton>
                    </MDBox>
                  </Form>
                </MDBox>
              </MDBox>
            );
          }}
        </Formik>
      </MDBox>
    </Modal>
  );
};
