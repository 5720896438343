import {Avatar} from "@mui/material";
import avatar from "../../assets/images/Ellipse.png";
import pxToRem from "../../assets/theme/functions/pxToRem";
import ActionsMenu from "../../components/ActionsMenu";
import Eye from "../../assets/icons/Eye.svg";
import editPen from "../../assets/icons/editPen.svg";
import trashRed from "../../assets/icons/trash_red.svg";
import warningCircle from "../../assets/icons/warningCircle.svg";


export const dataTableModel = {
  columns: [
    {Header: "Profile", accessor: "profile", disableOrdering: true, width: `${pxToRem(80)}`},
    {Header: "First Name", accessor: "first_name", width: `${pxToRem(100)}`},
    {Header: "Last Name", accessor: "last_name", width: `${pxToRem(100)}`},
    {Header: "Role", accessor: "role", disableOrdering: true, width: `${pxToRem(120)}`},
    {Header: "Email", accessor: "email", width: `${pxToRem(200)}`},
    {Header: "Phone Number", accessor: "phone_number", disableOrdering: true, width: `${pxToRem(150)}`},
    {Header: "Action", accessor: "action", disableOrdering: true, width: `${pxToRem(50)}`},
  ],
  rows: [],
};

const popOver = (item, setOpenView, setEditMode, setDataView, setOpenConfirmDelete, setUserId, setOpenFlagInput) => {
  return (
    <>
      <ActionsMenu
        menuContent={
          item.is_admin ?
            [
              {
                title: 'View Details',
                src: Eye,
                onClick: () => {
                  setOpenView(true)
                  setEditMode(false)
                  setDataView(item)
                }
              },
              {
                title: 'Edit',
                src: editPen,
                onClick: () => {
                  setOpenView(true)
                  setEditMode(true)
                  setDataView(item)
                }
              },
              {
                title: 'Delete',
                src: trashRed,
                onClick: () => {
                  setOpenConfirmDelete(true)
                  setUserId(item.id)
                }
              },
            ]
            :
            [
              {
                title: 'View Details',
                src: Eye,
                onClick: () => {
                  setOpenView(true)
                  setEditMode(false)
                  setDataView(item)
                }
              },
              {
                title: 'Flag',
                src: warningCircle,
                onClick: () => {
                  setOpenFlagInput(true)
                  setDataView(item)
                }
              },
              {
                title: 'Delete',
                src: trashRed,
                onClick: () => {
                  setOpenConfirmDelete(true)
                  setUserId(item.id)
                }
              },
            ]
        }
      />
    </>
  )
}

export const renderTableRow = (item, setOpenView, setEditMode, setDataView, setOpenConfirmDelete, setUserId, setOpenFlagInput) => {
  item.profile = <Avatar src={item?.profile_picture} alt={avatar}/>
  item.first_name= item.first_name ?? 'No data'
  item.last_name= item.last_name ?? 'No data'
  item.role = item.is_admin ? 'Admin' : 'Marketing Agent'
  item.email= item.email ?? 'No data'
  item.phone_number= item.phone_number ?? 'No data'
  item.action = (popOver(item, setOpenView, setEditMode, setDataView, setOpenConfirmDelete, setUserId, setOpenFlagInput))
  return item
}
