import AdminLayout from "../../components/AdminLayout";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import React, { useEffect, useRef, useState } from "react";
import {useApi} from "../../services/helpers";
import MDButton from "../../components/MDButton";
import DataTableSimple from "../../components/DataTableSimple";
import SearchBar from "../../components/SearchBar";
import { FieldArray, Form, Formik } from "formik";
import FormikInput from "../../components/FormikInput";
import { BaseModal } from "../../components/BaseModal";
import { QuizzesForm } from "./quizzes-form";
import ActionsMenu from "../../components/ActionsMenu";
import Eye from "../../assets/icons/Eye.svg";
import editPen from "../../assets/icons/editPen.svg";
import trashRed from "../../assets/icons/trash_red.svg";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";
import {
  dataTableModel,
  typeValues,
  filterValues,
  initialValues,
  validationSchemas,
} from "./constants";
import {linkRender} from "../../components/DataTableSimple/utils";
import {useStores} from "../../models";
import {CustomSelect} from "../../components/CustomSelect";
import {Grid} from "@mui/material";

export const AdminUploadContent = () => {
  const api = useApi();
  const formikRef = useRef();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [table, setTable] = useState(dataTableModel);
  const [searchQuery, setSearchQuery] = useState("");
  const [contentTypeSelector, setContentTypeSelector] = useState("FAQ");
  const [content, setContent] = useState(null);
  const [paginationData, setPaginationData] = useState({
    counts: 0,
    itemsPerPage: 0,
    currentPage: 1,
  });
  const rootStore = useStores()
  const {loginStore} = rootStore
  const [isViewDetails, setIsViewDetails] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [contentId, setContentId] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [value, setValue] = useState(typeValues[0]);
  // -----Languages from DB-------
  const [languageArray, setLanguageArray] = useState([]);
  const [language, setLanguage] = useState([])
  // -----------------------------
  const [filterOption, setFilterOption] = useState(filterValues[0]);
  const handleOpen = () => {
    setContent(null);
    setIsEdit(false);
    setIsViewDetails(false);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const handleCloseDelete = () => {
    setOpenConfirmDelete(false);
  };
  const handleConfirmDelete = () => {
    setOpenConfirmDelete(false);
    deleteContent(contentId);
  };
  const handleConfirmUpdate = (data) => {
    setIsEdit(data);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const getContent = (search = searchQuery, page = 1, ordering = "", page_size = 25) => {
    setLoading(true);
    api.getContent({ search, page, ordering, page_size }).handle({
      onSuccess: (result) => {
        const { data } = result;
        const DATA = data?.map((d) => {
          return {
            type: d.type ?? "No data",
            alt2: d.type ? "No data" : d.type,
            alt: d.type && "No data",
            alt1: d.type ? d.type : "No data",
            title: d.title ?? "No data",
            description: d.description ?? "No data",
            video: d.video ? linkRender(d.video) : "No data",
            thumbnail: d.thumbnail ? linkRender(d.thumbnail) : "No data",
            language: d.language? languageArray.filter(lang => lang.id === d.language.id)[0]?.name : "No data",
            action: (
              <>
                <ActionsMenu
                  menuContent={[
                    {
                      title: "View Details",
                      src: Eye,
                      onClick: () => {
                        setValue({ value: d.type, label: d.type });
                        setLanguage(languageArray.filter(lang => lang.id === d.language.id));
                        setContent(d);
                        setIsViewDetails(true);
                        setOpen(true);
                      },
                    },
                    {
                      title: "Edit",
                      src: editPen,
                      onClick: () => {
                        setValue({ value: d.type, label: d.type });
                        setLanguage(languageArray.filter(lang => lang.id === d.language));
                        setContent(d);
                        setContentId(d.id);
                        setIsEdit(true);
                        setIsViewDetails(false);
                        setOpen(true);
                      },
                    },
                    {
                      title: "Delete",
                      src: trashRed,
                      onClick: () => {
                        setOpenConfirmDelete(true);
                        setContentId(d.id);
                      },
                    },
                  ]}
                />
              </>
            ),
          };

        });
        setTable({ ...dataTableModel, rows: DATA });
        setPaginationData({ counts: data?.length, itemsPerPage: data?.length, currentPage: 1 });
      },
      errorMessage: "Error getting content",
      onError: err => {
        console.log(err)
      },
      onFinally: () => setLoading(false)
    });
  };

  // Same as getLanguages from CreateEditTemplateModal.js
  const getLanguages = () => {
    setLoading(true)
    api.getLanguages({}).handle({
      onSuccess: (result) => {
        const languagesBackend = result?.data
        setLanguageArray(Object.values(languagesBackend));
        setLoading(false)
      },
      errorMessage: 'Error getting languages',
      onFinally: () => setLoading(false)
    })
  }
  useEffect(() => {
    getLanguages()
  }, []);

  const postContent = (data) => {
    const newData = data?.type?.label !== "TRAINING" ? { ...data, quizzes: [] } : data;
    const valueFormatted = {...newData, type: newData.type.value}
    setLoading(true);
    api.postContent(valueFormatted).handle({
      onSuccess: (result) => {
        getContent();
        setOpen(false);
      },
      errorMessage: "Error creating content",
      successMessage: "Content uploaded",
      onFinally: () => setLoading(false),
    });
  };

  const deleteContent = (id) => {
    api.deleteContent(id).handle({
      onSuccess: () => {
        getContent();
      },
      errorMessage: "Error deleting content",
      successMessage: "Content deleted successfully",
    });
  };
  const updateContent = (data) => {
    setLoading(true);
    const valueFormatted = {...data, type: data.type.value}
    if(valueFormatted.video === content.video) delete valueFormatted.video
    if(valueFormatted.thumbnail === content.thumbnail) delete valueFormatted.thumbnail
    api.updateContent(valueFormatted, contentId).handle({
      onSuccess: () => {
        getContent();
        setOpen(false);
      },
      errorMessage: "Error updating content",
      successMessage: "Content updated successfully",
      onFinally: () => setLoading(false),
    });
  };

  useEffect(() => {
    getContent();
  }, [searchQuery]);

  return (
    <AdminLayout title={"Content"}>
      <BaseModal
        open={open}
        handleClose={handleClose}
        height={value?.value === "FAQ" ? "60vh" : "98vh"}
      >
        <Formik
          innerRef={formikRef}
          initialValues={initialValues(content)}
          validationSchema={validationSchemas[contentTypeSelector]}
          validateOnChange={false}
          onSubmit={(values) => (isEdit ? updateContent(values) : postContent(values))}
        >
          {(formik) => {
            return (
              <MDBox>
                <MDTypography pt={2} sx={{ textAlign: "center" }}>
                  Upload Content
                </MDTypography>
                <MDBox p={3}>
                  <Form>
                    <MDBox>
                      <FormikInput
                        fieldName={"type"}
                        label={"Select a Type"}
                        readOnly={isViewDetails}
                        type={'autocomplete'}
                        value={formik?.values?.type}
                        accessKey={"label"}
                        options={typeValues}
                        onChange={e => {
                          setContentTypeSelector(e?.value)
                          setValue(e)
                          formik.setFieldValue('type', e)
                        }}
                        errors={formik.errors}
                      />
                      <FormikInput
                        fieldName={"language"}
                        label={"Select a Language"}
                        readOnly={isViewDetails}
                        value={formik.values.language}
                        type={'autocomplete'}
                        accessKey={"name"}
                        options={languageArray}
                        onChange={e => {
                          setLanguage(e)
                          formik.setFieldValue('language', e)
                        }}
                        errors={formik.errors}
                      />
                    </MDBox>
                    <MDBox my={2}>
                      <FormikInput
                        name={"title"}
                        label={"Title"}
                        disabled={isViewDetails}
                        errors={formik.errors}
                      />
                    </MDBox>
                    {value?.value === "FAQ" && (
                        <FormikInput
                          name={"description"}
                          label={"Description"}
                          disabled={isViewDetails}
                          type={"textarea"}
                          rows={5}
                          errors={formik.errors}
                        />
                    )}
                    {value?.value === "VIDEO" && (
                      <>
                        <FormikInput
                          name={"duration"}
                          label={"Duration"}
                          disabled={isViewDetails}
                          errors={formik.errors}
                        />
                        <FormikInput
                          name={"video"}
                          label={"Video"}
                          accept={"video"}
                          type={"drag_and_drop"}
                          disabled={isViewDetails}
                          errors={formik.errors}
                          onDragOver={(e) => e.preventDefault()}
                          onDrop={(e) => {
                            e.preventDefault();
                            formik.setFieldValue("video", e.dataTransfer.files?.[0]);
                          }}
                          onInput={(event) => {
                            formik.setFieldValue("video", event.target.files[0]);
                          }}
                          onClick={(event) => {
                            event.target.value = null;
                          }}
                          values={formik.values}
                          setFieldValue={formik.setFieldValue}
                        />
                        <FormikInput
                          name={"thumbnail"}
                          label={"Thumbnail image"}
                          accept={"image"}
                          type={"drag_and_drop"}
                          disabled={isViewDetails}
                          errors={formik.errors}
                          onDragOver={(e) => e.preventDefault()}
                          onDrop={(e) => {
                            e.preventDefault();
                            formik.setFieldValue("thumbnail", e.dataTransfer.files?.[0]);
                          }}
                          onInput={(event) => {
                            formik.setFieldValue("thumbnail", event.target.files[0]);
                          }}
                          onClick={(event) => {
                            event.target.value = null;
                          }}
                          values={formik.values}
                          setFieldValue={formik.setFieldValue}
                        />
                      </>
                    )}
                    {value?.value === "TRAINING" && (
                      <>
                        <FormikInput
                          name={"duration"}
                          label={"Duration"}
                          disabled={isViewDetails}
                          errors={formik.errors}
                        />
                        <FormikInput
                          name={"video"}
                          label={"Video"}
                          accept={"video"}
                          type={"drag_and_drop"}
                          disabled={isViewDetails}
                          errors={formik.errors}
                          onDragOver={(e) => e.preventDefault()}
                          onDrop={(e) => {
                            e.preventDefault();
                            formik.setFieldValue("video", e.dataTransfer.files?.[0]);
                          }}
                          onInput={(event) => {
                            formik.setFieldValue("video", event.target.files[0]);
                          }}
                          onClick={(event) => {
                            event.target.value = null;
                          }}
                          values={formik.values}
                          setFieldValue={formik.setFieldValue}
                        />
                        <FormikInput
                          name={"thumbnail"}
                          label={"Thumbnail image"}
                          accept={"image"}
                          type={"drag_and_drop"}
                          disabled={isViewDetails}
                          errors={formik.errors}
                          onDragOver={(e) => e.preventDefault()}
                          onDrop={(e) => {
                            e.preventDefault();
                            formik.setFieldValue("thumbnail", e.dataTransfer.files?.[0]);
                          }}
                          onInput={(event) => {
                            formik.setFieldValue("thumbnail", event.target.files[0]);
                          }}
                          onClick={(event) => {
                            event.target.value = null;
                          }}
                          values={formik.values}
                          setFieldValue={formik.setFieldValue}
                        />
                        <FieldArray name={"quizzes"}>
                          {(quizzesProps) => (
                            <QuizzesForm
                              quizzesProps={quizzesProps}
                              formik={formik}
                              isViewDetails={isViewDetails}
                            />
                          )}
                        </FieldArray>
                      </>
                    )}
                    <MDBox
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      gap={2}
                      pt={2}
                    >
                      <MDButton
                        disabled={isViewDetails || loading}
                        loading={loading}
                        color={"primary"}
                        sx={{ minWidth: 97 }}
                        onClick={() => formik.handleSubmit()}
                      >
                        Submit
                      </MDButton>
                      <MDButton variant={"outlined"} onClick={handleClose}>
                        Cancel
                      </MDButton>
                    </MDBox>
                  </Form>
                </MDBox>
              </MDBox>
            );
          }}
        </Formik>
      </BaseModal>
      <MDBox width={"100%"}>
        <MDBox
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          mb={"10px"}
        >
          <Grid container direction={{xs:"column", md:'row'}} alignItems={"center"}>
            <Grid container item xs={12} md={8} columnSpacing={2}>
              <Grid item xs={6} md={6}>
            <SearchBar loading={loading} search={getContent} setSearchQuery={setSearchQuery} />
              </Grid>
              <Grid item justifyContent={"end"} xs={5}>
              <CustomSelect
                value={filterOption}
                options={filterValues}
                onChange={(event, value) => {
                  setFilterOption(value);
                  setSearchQuery(value.value);
                }}
                sx={{ width: "150px", borderRadius: "10px" }}
              />
            </Grid>
          </Grid>
            <Grid container item xs={12} md={4} justifyContent={'end'}>
              <MDButton
                  sx={{width:{xs:'100%', md:'auto'}, height: "44px", borderRadius: "10px" }}
            color={"primary"}

            onClick={handleOpen}
              >
            Upload Content
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox>
          <DataTableSimple table={table} />
        </MDBox>
        <ConfirmModal
          handleClose={handleCloseDelete}
          open={openConfirmDelete}
          handleConfirm={handleConfirmDelete}
          title={"Are you sure?"}
          subtitle={"Are you sure you want to delete this content?"}
        />
        <ConfirmModal
          handleClose={handleCloseConfirm}
          open={openConfirm}
          handleConfirm={handleConfirmUpdate}
          title={"Are you sure?"}
          subtitle={"Are you sure you want to update this content?"}
        />
      </MDBox>
    </AdminLayout>
  );
};
