export const ROLES = {
  ADMIN: {id: 1, name: "Admin"},
  PROJECT_MANAGER: {id: 2, name: "Project Manager"},
  SUPPLY_MANAGER: {id: 3, name: "Supply Manager"},
}

export const API_VERSION_PREFIX = '/api/v1'

export const ROUTES = {
  ROOT: "/",
  LOGOUT: "/logout",
  LOGIN: "/",
  FORGOT_PASSWORD: "/forgot-password",
  SET_NEW_PASSWORD: "/set-new-password/*",
  ACTIVATE_USER: "/activate-user/*",
// Project manager routes
  USER_PROJECT_SELECTOR: `/projects`,
  USER_MY_ACCOUNT: `/my-account`,
  USER_CHANGE_PASSWORD: `/change-password`,
  USER_TERMS_AND_CONDITIONS: `/terms-and-conditions`,
  USER_PRIVACY_POLICY: `/privacy-policy`,
  USER_ACCOUNT_SETTINGS: `/account-settings`,
  DIRECT_MESSAGES: (id) => `/project/${id}/direct-messages`,
  NOTIFICATIONS: `/notifications/`,
  ORDER_DETAILS_PROJECT_MANAGER: `/order-details`,
  SUCCESS_DOCUSIGN: `/success`,


// Admin routes
  ADMIN_ANALYTICS: '/dashboard/analytics',
  ADMIN_ALL_USERS: '/dashboard/all-users',
  ADMIN_CREATE_NEW_USER: '/dashboard/create-user-account',
  ADMIN_CREATE_NEW_PRODUCT: '/management/manage-products/new',
  ADMIN_PRODUCTS: '/management/manage-products/',
  ADMIN_CREATE_NEW_BRAND: '/management/manage-brands/new',
  ADMIN_BRAND: '/management/manage-brands',
}

export const PM_TASKS = {
  SHARE: 'share',
  APPROVE_ORDER: 'approve_order',
  EDIT_ORDER: 'edit_order',
  APPROVE_CONTRACT: 'approve_contract',
  REJECT: 'reject',
}

export const VENDOR_TASKS = {
  APPROVE_ORDER: 'approve_order',
  EDIT_ORDER: 'edit_order',
  APPROVE_CONTRACT: 'approve_contract',
  REJECT: 'reject',
  MARK_FULFILLED: 'mark_fulfilled',
}
