/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";


// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";

import logo from "assets/images/logos/logo.png";
import PageLayout from "../PageLayout";
import pxToRem from "../../assets/theme/functions/pxToRem";
import arrow_left from "assets/icons/CaretLeftActivated.svg";
import {useNavigate} from "react-router-dom";

function IllustrationLayout({ header, title, description, illustration, children, goBackOption=false }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const navigate = useNavigate()

  return (
    <PageLayout>
      <Grid
        container
        position="relative"
        sx={{
          backgroundColor: ({ palette: { background, black } }) =>
            darkMode ? background.default : black.main,
        }}
      >
        <Grid item xs={12} lg={6}>
          <MDBox
            display={{ xs: "none", lg: "flex" }}
            width="calc(100%)"
            height="calc(100vh)"
            mr={2}
            sx={{ backgroundImage: `url(${illustration})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}
          />
        </Grid>
        <Grid item xs={12} lg={6} position="relative">
          {goBackOption && <MDBox component={'img'} src={arrow_left} alt={'arrow left'} position="absolute" width={30} ml={4} mt={25} sx={{ cursor: 'pointer' }} onClick={() => navigate(-1)} />}
          <Grid item xs={12} sm={8} md={8} lg={8} xl={6}  sx={{ mx: "auto" }}>
            <MDBox display="flex" flexDirection="column" justifyContent="center" height="100vh">
              <MDBox py={3} px={3} textAlign="center">
                <MDBox
                  component="img"
                  src={logo}
                  alt={'logo'}
                  width={pxToRem(150)}
                  position="relative"
                  zIndex={1}
                  mb={10}
                />
                {!header ? (
                  <>
                    <MDBox mb={1} textAlign="left">
                      <MDTypography variant="h4" fontWeight="bold">
                        {title}
                      </MDTypography>
                    </MDBox>
                    {description !== '' && <MDTypography color="white" sx={{fontSize: pxToRem(12), fontWeight: 400}}  textAlign={'left'}>
                      {description}
                    </MDTypography> }
                  </>
                ) : (
                  header
                )}
              </MDBox>
              <MDBox p={3}>{children}</MDBox>
              <MDTypography textAlign={"center"} color={"primary"} fontWeight={"light"}>Administration</MDTypography>
            </MDBox>
          </Grid>
        </Grid>
      </Grid>
    </PageLayout>
  );
}

// Setting default values for the props of IllustrationLayout
IllustrationLayout.defaultProps = {
  header: "",
  title: "",
  description: "",
  illustration: "",
};

// Typechecking props for the IllustrationLayout
IllustrationLayout.propTypes = {
  header: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node.isRequired,
  illustration: PropTypes.string,
};

export default IllustrationLayout;
