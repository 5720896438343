import * as Yup from "yup";

const NUM_TYPE = 'Must be a number type'
const POSITIVE_NUM = 'Must be a positive number'
const NO_MATH_SIGNS = "This field cannot be empty or contain (e), (+), or (-)"
const signsValidator = (testValue) => typeof testValue === "number" && !/[eE+-]/.test(testValue.toString())

export const validationSchema =
  Yup.object().shape({
    points: Yup.number(NUM_TYPE).positive(POSITIVE_NUM).test("noEOrSign", NO_MATH_SIGNS, (value) => signsValidator(value)),
    usd: Yup.number(NUM_TYPE).positive(POSITIVE_NUM).test("noEOrSign", NO_MATH_SIGNS, (value) => signsValidator(value)),
    leads: Yup.number(NUM_TYPE).positive(POSITIVE_NUM).test("noEOrSign", NO_MATH_SIGNS, (value) => signsValidator(value)),
    qualified_leads: Yup.number(NUM_TYPE).positive(POSITIVE_NUM).test("noEOrSign", NO_MATH_SIGNS, (value) => signsValidator(value)),
    level_2: Yup.number(NUM_TYPE).positive(POSITIVE_NUM).test("noEOrSign", NO_MATH_SIGNS, (value) => signsValidator(value)),
    level_3: Yup.number(NUM_TYPE).positive(POSITIVE_NUM).test("noEOrSign", NO_MATH_SIGNS, (value) => signsValidator(value)),
    level_4: Yup.number(NUM_TYPE).positive(POSITIVE_NUM).test("noEOrSign", NO_MATH_SIGNS, (value) => signsValidator(value)),
    level_5: Yup.number(NUM_TYPE).positive(POSITIVE_NUM).test("noEOrSign", NO_MATH_SIGNS, (value) => signsValidator(value)),
    social_post: Yup.number(NUM_TYPE).positive(POSITIVE_NUM).test("noEOrSign", NO_MATH_SIGNS, (value) => signsValidator(value)),
    minimum_points: Yup.number(NUM_TYPE).positive(POSITIVE_NUM).test("noEOrSign", NO_MATH_SIGNS, (value) => signsValidator(value)),
    text_reach: Yup.number(NUM_TYPE).positive(POSITIVE_NUM).test("noEOrSign", NO_MATH_SIGNS, (value) => signsValidator(value)),
    text_reach_recipients: Yup.number(NUM_TYPE).positive(POSITIVE_NUM).test("noEOrSign", NO_MATH_SIGNS, (value) => signsValidator(value)),
    text_reach_limits: Yup.number(NUM_TYPE).positive(POSITIVE_NUM).test("noEOrSign", NO_MATH_SIGNS, (value) => signsValidator(value)),
  })

export const initialValues = (data)=> {
  return {
    id: data?.id ? data.id : null,
    points: data?.points ? data.points : "",
    usd: data?.usd ? data.usd : "",
    leads: data?.leads ? data.leads : "",
    qualified_leads: data?.qualified_leads ? data.qualified_leads : "",
    level_2: data?.level_2 ? data.level_2 :"",
    level_3: data?.level_3 ? data.level_3 :"",
    level_4: data?.level_4 ? data.level_4 :"",
    level_5: data?.level_5 ? data.level_5 :"",
    social_post: data?.social_post ? data.social_post : "",
    minimum_points: data?.minimum_points ? data.minimum_points : "",
    text_reach: data?.text_reach ? data.text_reach : "",
    text_reach_recipients: data?.text_reach ? data.text_reach : "",
    text_reach_limits: data?.text_reach ? data.text_reach : "",
  }
};
