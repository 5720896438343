import MDBox from "../MDBox";
import { Form, Formik } from "formik";
import MDTypography from "../MDTypography";
import Autocomplete from "@mui/material/Autocomplete";
import MDInput from "../MDInput";
import FormikInput from "../FormikInput";
import MDButton from "../MDButton";
import { Modal } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import * as Yup from "yup";

function NeedDetailModal({ handleClose, open, handleConfirm, title }) {
  const formikRef = useRef();

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(),
    body: Yup.string().required(),
  })
  
  const initialValues = {
   title: '',
   body: ''
  };
  

  return (
    <Modal open={open} onClose={handleClose}>
      <MDBox sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        backgroundColor: '#282828',
        borderRadius: '10px',
        transform: 'translate(-50%, -50%)',
        paddingX: '20px',
        width: 700,
        boxShadow: 24,
      }}>
        <MDTypography pt={2} sx={{ textAlign: 'center', fontSize: "22px", marginBottom: "15px" }}>{title}</MDTypography>


        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnBlur={false}
          onSubmit={values => handleConfirm(values)}
        >
          {(formik) => (
            <Form>
              <FormikInput name={'title'} placeholder={'Title'} errors={formik.errors} />
              <FormikInput name={'body'} type={'textarea'} rows={3} placeholder={'Write a response here...'} errors={formik.errors} />
              <MDBox pb={2} display={'flex'} justifyContent={'center'} alignItems={'center'} gap={2} pt={2}>
                <MDButton color={'primary'} type='submit'>Confirm</MDButton>
                {/* // handleConfirm() */}
                <MDButton variant={'outlined'} onClick={() => {
                  handleClose()
                }}>Cancel</MDButton>
              </MDBox>

            </Form>
          )}
        </Formik>



      </MDBox>
    </Modal>
  )
}

export default NeedDetailModal
