import AdminLayout from "../../components/AdminLayout";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import React, {useEffect, useState} from "react";
import {Grid} from "@mui/material";
import {useApi} from "../../services/helpers";
import {PaginationCustom} from "../../components/DataTableSimple/PaginationCustom";
import SearchBar from "../../components/SearchBar";
import templateIcon from "assets/icons/template.svg"
import trash from "assets/icons/trash_gold.svg"
import pencil from "assets/icons/pencil_gold.svg"
import MDButton from "../../components/MDButton";
import {CreateEditTemplateModal} from "./CreateEditTemplateModal";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";
export const AdminTemplates = () => {
  const api = useApi()
  const [loading, setLoading] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [template, setTemplate] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [templates, setTemplates] = useState([]);
  const [pageSize, setPageSize] = useState(4);
  const [paginationData, setPaginationData] = useState({counts: 0, itemsPerPage: 0, start: 1, end: 4, currentPage: 1});

  const handleCloseDelete = () => {
    setDeleteModal(false)
  }
  const handleConfirmDelete = () => {
    deleteTemplate(template.id)
    setDeleteModal(false)
  }

  const handleUpdate = (item) => {
    setIsEdit(true)
    setTemplate(item)
    setCreateModal(true)
  }

  const deleteTemplate = (id) => {
    setLoading(true)
    api.deleteTemplate(id).handle({
      onSuccess: () => {
        getTemplates({search: searchQuery});
        setLoading(false)
      },
      errorMessage: 'Error deleting templates',
      successMessage: 'Template deleted successfully',
      onFinally: () => setLoading(false)

    })
  }

  const createTemplate = (data) => {
    setLoading(true)
    api.createTemplate(data).handle({
      onSuccess: (result) => {
        getTemplates({search: searchQuery});
        setLoading(false)
      },
      onError: err => {
        console.log(err)
      },
      errorMessage: 'Error creating templates',
      successMessage: 'Template created successfully',
      onFinally: () => setLoading(false)
    })
  }

  const getTemplates = (search) => {
    setLoading(true)
    api.getTemplates({...search, page_size: pageSize}).handle({
      onSuccess: (result) => {
        const {count, end, start, results} = result?.data
        setLoading(false)
        setTemplates(results)
        setPaginationData(value => ({...value, counts: count, start: start, end: end, itemsPerPage: results?.length}))
      },
      errorMessage: 'Error getting templates',
      onFinally: () => setLoading(false)
    })
  }



  const updateTemplate = (data) => {
    api.updateTemplate(data, template.id).handle({
      onSuccess: () => {
        getTemplates({search: searchQuery});
      },
      errorMessage: "Error updating templates",
      successMessage: "Template updated successfully",
      onFinally: () => setLoading(false)
    });
  };
  const getFilteredPage = () =>{
    let filters =  {search: searchQuery, page: 1}
    setPaginationData(value => ({...value, currentPage: filters.page}))
    return filters
  }

  useEffect(() => {
    getTemplates({search: searchQuery, page: paginationData.currentPage});
  }, [searchQuery, pageSize])


return (
    <AdminLayout
      title={'Templates'}
    >
      <MDBox width={'100%'} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"flex-start"}>
        <MDBox display={"flex"} justifyContent={"space-between"} width={'100%'}>
          <SearchBar loading={loading} search={getTemplates} setSearchQuery={setSearchQuery}/>
          <MDButton
            color={"primary"}
            sx={{ height: "44px", borderRadius: "10px" }}
            onClick={() => {
              setIsEdit(false)
              setCreateModal(true)
              }
            }
          >
            Add New Template
          </MDButton>
        </MDBox>
        <Grid container mt={2} spacing={4}>
          {templates?.length ? templates.map((item, index) => {
              return (
                <Grid key={`id-${item.id}`} item md={6} height={285}>
                  <MDBox bgColor={'#282828'} height={'100%'} borderRadius={'md'} px={2}>
                    <MDBox display={"flex"} justifyContent={"space-between"}>
                      <MDBox display={"flex"} alignItems={"center"} justifyContent={"space-between"} py={2} width={'100%'}>
                        <MDBox display={'flex'} alignItems={'center'} gap={1}>
                          <MDBox component={'img'} src={templateIcon} alt={'template'} mr={1}/>
                          <MDTypography
                            variant={'subtitle2'}
                            > {`Template ${index + 1}`}
                          </MDTypography>
                        </MDBox>
                        <MDBox display={'flex'} alignItems={'center'} gap={1}>
                          <MDBox component={'img'} src={pencil} alt={'pencil'} sx={{ cursor: 'pointer' }} onClick={() => handleUpdate(item)}/>
                          <MDBox component={'img'} src={trash} alt={'trash'} sx={{ cursor: 'pointer' }} onClick={() => {setTemplate(item); setDeleteModal(true)}}/>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                    <MDBox py={2} display={'flex'} flexDirection={'column'} gap={1}>
                      <MDTypography
                        variant={'subtitle2'}
                        color={'primary'}
                      >
                        Message:
                      </MDTypography>
                      <MDTypography
                        variant={'subtitle2'}
                      >
                        {item.message}
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </Grid>

              )
            }) :
            <MDBox display={'flex'} alignItems={'center'} justifyContent={'center'} minHeight={'50vh'} width={'100%'}>
              <MDTypography variant={'subtitle1'}>No data found</MDTypography>
            </MDBox>
          }
        </Grid>
        <ConfirmModal
          handleClose={handleCloseDelete}
          open={deleteModal}
          handleConfirm={handleConfirmDelete}
          title={"Are you sure?"}
          subtitle={"Are you sure you want to delete this template?"}
        />
        <CreateEditTemplateModal
          data={isEdit ? template : null}
          open={createModal}
          title={isEdit ? 'Update Template' : 'Create New Template'}
          handleClose={() => setCreateModal(false)}
          handleSubmit={isEdit ? updateTemplate : createTemplate}
        />
        <MDBox width={'100%'} height={80}>
          <PaginationCustom
            currentPage={paginationData.currentPage}
            totalCount={paginationData.counts}
            startPage={paginationData.start}
            endPage={paginationData.end}
            setPageSize={setPageSize}
            pageSize={pageSize}
            onPageChange={page => {
              getTemplates({ ...getFilteredPage(), page:page})
              setPaginationData(value => ({...value, currentPage: page}))
            }}
          />

        </MDBox>
      </MDBox>
    </AdminLayout>
  );
}
