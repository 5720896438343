/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import {useState, useEffect} from "react";

// react-router components
import {Routes, Route, useLocation} from "react-router-dom";

// @mui material components
import {ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Dashboard 2 PRO React examples
import Sidenav from "components/Sidenav";

// Material Dashboard 2 PRO React themes
import theme from "assets/theme";

// Material Dashboard 2 PRO React Dark Mode themes
import themeDark from "assets/theme-dark";

// Material Dashboard 2 PRO React routes
import routes from "routes";

// Material Dashboard 2 PRO React contexts
import {useMaterialUIController} from "context";

// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import {setupRootStore} from "./models";
import icon from "./assets/icons/favicon.png";
import {ROUTES} from "./services/constants";

// Pages
import SignIn from "./pages/sign-in";
import NotFound from "./pages/not-found";
import ForgotPassword from "./pages/forgot-password";
import SetNewPassword from "./pages/set-new-password";
import ActivateUser from "./pages/activate-user";
import {SuccessDocuSign} from "./pages/success-docu-sign";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {direction, layout, sidenavColor, transparentSidenav, whiteSidenav, darkMode} = controller;
  const {pathname} = useLocation();

  const [rootStore, setRootStore] = useState(undefined);

  useEffect(() => {
    const favicon = document.getElementById("favicon");
    favicon.setAttribute("href", icon);
  }, []);

  useEffect(() => {
    (async () => {
      setupRootStore().then((rootStore) => {
        setRootStore(rootStore);
      });
    })();
  }, []);

  useEffect(() => {
    if (rootStore) {
      rootStore.loginStore.setUp();
    }
  }, [rootStore]);

  //Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <Sidenav
          color={sidenavColor}
          brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
          brandName=""
          routes={routes}
        />
      )}
      <Routes>
        {getRoutes(routes)}
        <Route path={ROUTES.LOGIN} element={<SignIn />} />
        <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPassword />} />
        <Route path={ROUTES.SET_NEW_PASSWORD} element={<SetNewPassword />} />
        <Route path={ROUTES.ACTIVATE_USER} element={<ActivateUser />} />
        <Route path={ROUTES.SUCCESS_DOCUSIGN} element={<SuccessDocuSign />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </ThemeProvider>
  );
}
