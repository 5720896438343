import AdminLayout from "../../components/AdminLayout";
import MDBox from "../../components/MDBox";
import React, { useEffect, useState } from "react";
import { useApi } from "../../services/helpers";
import SearchBar from "../../components/SearchBar";
import { ApproveDenyModal } from "./ApproveDenyModal";
import { dataTableModel, renderTableRow } from "./utils";
import DataTable from "../../components/DataTable";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";
import NeedDetailModal from "../../components/NeedDetailModal/NeedDetailModal";


export const AdminSocialPosts = () => {
  const api = useApi()
  const [loading, setLoading] = useState(false);
  const [datatable, setDatatable] = useState({ ...dataTableModel })
  const [searchQuery, setSearchQuery] = useState('');
  const [paginationData, setPaginationData] = useState({
    counts: 0,
    itemsPerPage: 0,
    currentPage: 1,
    ordering: ""
  });
  const values = [
    { value: 1, label: 'All' },
    { value: 2, label: 'Admin' },
    { value: 3, label: 'Marketing Agent' }
  ];
  const [value, setValue] = useState(values[0]);
  const [openDeny, setOpenDeny] = useState(false);
  const [openNeedDetail, setOpenNeedDetail] = useState(false);
  const [openConfirmDe, setOpenConfirmCreateAdmin] = useState(false);
  const [openApprove, setOpenApprove] = useState(false);
  const [openCreateAdmin, setOpenCreateAdmin] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [dataView, setDataView] = useState(null)
  const [userId, setUserId] = useState(null)
  const [typeUser, setTypeUser] = useState("")
  const handleApprove = () => {
    ApprovePoints(userId)
    setUserId(null)
    setOpenApprove(false)
  }
  const handleCloseApprove = () => {
    setOpenApprove(false)
  }

  const handleCloseDeny = () => {
    setOpenDeny(false)
  }
  const handleConfirmDeny = () => {
    DenyPoints(userId)
    setOpenDeny(false)
  }
  const getSocialPosts = (search, page = 1, ordering = "") => {
    setLoading(true)
    api.getSocialPosts({ search, page, page_size: pageSize, type: typeUser, ordering }).handle({
      onSuccess: (result) => {
        const { count, end, start, data } = result
        const tmp = { ...dataTableModel }
        tmp.rows = data?.map(e => renderTableRow(e, setUserId, setOpenApprove, setDataView, setOpenDeny, setOpenNeedDetail))
        setDatatable(tmp)
        setPaginationData(value => ({
          ...value,
          counts: count,
          start,
          end,
          itemsPerPage: data?.length,
          ordering
        }))
      },
      errorMessage: 'Error getting posts',
      onError: err => {
        console.log(err)
      },
      onFinally: () => setLoading(false)
    }
    )
  }

  const sendConfirmationEmail = (data) => {
    api.sendConfirmationEmail(userId, data).handle({
      onSuccess: () => {
        setUserId(null)
        setOpenNeedDetail(false)
        getSocialPosts()
      },
      errorMessage: "Sorry an error occurred",
      successMessage: "Email sent",
    })
  }

  const DenyPoints = (id) => {
    api.denySocialPosts(id).handle({
      onSuccess: () => {
        getSocialPosts();
      },
      errorMessage: "Sorry an error occurred",
      successMessage: "Post denied",
    });
  }

  const ApprovePoints = (id) => {
    api.approveSocialPosts(id).handle({
      onSuccess: (result) => {
        getSocialPosts();
      },
      successMessage: "Post approved",
      errorMessage: "Sorry an error occurred",
    });
  }

  const getFilteredPage = () => {
    let filters = { search: searchQuery, page: 1 }
    setPaginationData(value => ({ ...value, currentPage: filters.page }))
    return filters
  }

  useEffect(() => {
    getSocialPosts()
  }, [])

  useEffect(() => {
    const { search, page } = getFilteredPage()
    getSocialPosts(search, page)
  }, [searchQuery, pageSize])

  return (
    <AdminLayout
      title={'Social Posts'}
    >
      <MDBox width={"100%"}>
        <MDBox display={"flex"} justifyContent={"space-between"}>
        </MDBox>
        <MDBox display={"flex"} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}
          mb={"10px"}>
          <MDBox display={"flex"} flexDirection={"row"} alignItems={"center"}>

            <SearchBar loading={loading} setSearchQuery={setSearchQuery} /><MDBox>
            </MDBox>
          </MDBox>
        </MDBox>
        <MDBox>
          <DataTable
            loading={loading}
            table={datatable}
            currentPage={paginationData.currentPage}
            numberOfItems={paginationData.counts}
            numberOfItemsPage={paginationData.itemsPerPage}
            start={paginationData.start}
            end={paginationData.end}
            searchFunc={getSocialPosts}
            searchQuery={searchQuery}
            setPageSize={setPageSize}
            pageSize={pageSize}
            onPageChange={page => {
              const { search } = getFilteredPage()
              getSocialPosts(search, page)
              setPaginationData(value => ({ ...value, currentPage: page }))
            }}
          />
        </MDBox>
        <ConfirmModal
          title={'Are sure you want to deny it?'}
          open={openDeny}
          data={dataView}
          handleClose={handleCloseDeny}
          handleConfirm={handleConfirmDeny}
        />
        <ConfirmModal
          title={'Are sure you want to approve it?'}
          open={openApprove}
          data={dataView}
          handleClose={handleCloseApprove}
          handleConfirm={handleApprove}
        />
        <NeedDetailModal
          title={'Need more details (email)'}
          open={openNeedDetail}
          data={dataView}
          handleClose={() => setOpenNeedDetail(false)}
          handleConfirm={(data) => sendConfirmationEmail(data)}
        />
      </MDBox>
    </AdminLayout>
  );
}

