// Images
import AdminAnalytics from "./pages/analytics";
import Logout from "./pages/logout";
import { AdminFeedback } from "./pages/feedback";
import { AdminUploadContent } from "./pages/upload-content";
import {
  Bell,
  Broadcast,
  ChartPieSlice,
  ChatCircleDots,
  CurrencyCircleDollar,
  Folders,
  Info,
  PaperPlaneTilt,
  SignOut,
  Tasks,
  Upload,
  Users,
  Wallet,
  ReferralIcon
} from "assets/theme/components/SvgIcons";
import AdminUsers from "./pages/users";
import { AdminNotifications } from "./pages/notifications";
import AdminBroadcasts from "./pages/broadcasts";
import AdminLeads from "./pages/leads"
import { AdminTemplates } from "./pages/templates";
import { AdminPointLegend } from "./pages/points-legend";
import { AdminDailyTasks } from "./pages/daily-tasks";
import AdminRedeemPoints from "./pages/redeem-points";
import { AdminSocialPosts } from "./pages/social-posts";
import AdminWallet from "pages/wallet";

const routes = [
  {
    type: "collapse",
    name: "Analytics",
    key: "analytics",
    route: "/dashboard/analytics",
    component: <AdminAnalytics />,
    noCollapse: true,
    icon: <ChartPieSlice />,
  },
  {
    type: "collapse",
    name: "Users",
    key: "users",
    route: "/dashboard/users",
    component: <AdminUsers />,
    noCollapse: true,
    icon: <Users />,
  },
  {
    type: "collapse",
    name: "Feedback",
    key: "feedback",
    route: "/dashboard/feedback",
    component: <AdminFeedback />,
    noCollapse: true,
    icon: <ChatCircleDots />,
  },
  {
    type: "collapse",
    name: "Content",
    key: "content",
    route: "/dashboard/content",
    component: <AdminUploadContent />,
    noCollapse: true,
    icon: <Upload />,
  },
  {
    type: "collapse",
    name: "Templates",
    key: "templates",
    route: "/dashboard/templates",
    component: <AdminTemplates />,
    noCollapse: true,
    icon: <Folders />,
  },
  {
    type: "collapse",
    name: "Social Posts",
    key: "social-posts",
    route: "/dashboard/social-posts",
    component: <AdminSocialPosts />,
    noCollapse: true,
    icon: <PaperPlaneTilt />,
  },
  {
    type: "collapse",
    name: "Broadcasts",
    key: "broadcasts",
    route: "/dashboard/broadcasts",
    component: <AdminBroadcasts />,
    noCollapse: true,
    icon: <Broadcast />,
  },
  {
    type: "collapse",
    name: "Leads",
    key: "leads",
    route: "/dashboard/leads",
    component: <AdminLeads />,
    noCollapse: true,
    icon: <ReferralIcon />,
  },
  {
    type: "collapse",
    name: "Redeem Points",
    key: "redeem-points",
    route: "/dashboard/redeem-points",
    component: <AdminRedeemPoints />,
    noCollapse: true,
    icon: <CurrencyCircleDollar />,
  },
  {
    type: "collapse",
    name: "Points Legend",
    key: "points-legend",
    route: "/dashboard/points-legend",
    component: <AdminPointLegend />,
    noCollapse: true,
    icon: <Info />,
  },
  {
    type: "collapse",
    name: "Notifications",
    key: "notifications",
    route: "/dashboard/notifications",
    component: <AdminNotifications />,
    noCollapse: true,
    icon: <Bell />,
  },
  {
    type: "collapse",
    name: "Daily Tasks",
    key: "tasks",
    route: "/dashboard/tasks",
    component: <AdminDailyTasks />,
    noCollapse: true,
    icon: <Tasks />,
  },
  {
    type: "collapse",
    name: "Wallet",
    key: "wallet",
    route: "/dashboard/wallet",
    component: <AdminWallet />,
    noCollapse: true,
    icon: <Wallet />,
  },
  {
    type: "collapse",
    name: "Logout",
    key: "logout",
    route: "/dashboard/logout",
    component: <Logout />,
    noCollapse: true,
    icon: <SignOut />,
  },
];

export default routes;
