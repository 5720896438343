import MDBox from "../../components/MDBox";
import {Form, Formik} from "formik";
import MDTypography from "../../components/MDTypography";
import FormikInput from "../../components/FormikInput";
import MDButton from "../../components/MDButton";
import {Modal} from "@mui/material";
import {useRef, useState, useEffect} from "react";
import * as Yup from "yup";
import {dataTableModel, renderTableRow} from "./utils";
import {useApi} from "../../services/helpers";

export const validationSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  description: Yup.string().required("Description is required"),
});
export const initialValues = (data) => {
  let targets = [];
  if (data?.targets) {
    targets = data.targets.map((t) => t.id);
  }

  return {
    title: data?.title ? data?.title : "",
    description: data?.description ? data?.description : "",
    targets,
  };
};

export const CreateNotificationModal = ({open, isEdit, data, handleClose, handleSubmit}) => {
  const formikRef = useRef();
  const api = useApi();
  const [loading, setLoading] = useState(false);
  const [datatable, setDatatable] = useState({...dataTableModel});
  const [searchQuery, setSearchQuery] = useState("");
  const [paginationData, setPaginationData] = useState({
    counts: 0,
    itemsPerPage: 0,
    currentPage: 1,
    ordering: "",
  });
  const [pageSize, setPageSize] = useState(10);

  const [checkedUsers, setCheckedUsers] = useState([]);
  const [users, setUsers] = useState([]);

  const getAllUsers = (search, page = 1, ordering = "") => {
    setLoading(true);
    api.getAllUsers({search, page, page_size: pageSize, ordering}).handle({
      onSuccess: (result) => {
        const {count, end, start, results} = result?.data;
        // setUsers(results);
        const newUsers = results.map((item) => {
          item.full_name = item.full_name.trim();
          return {
            id: item.id,
            label: item.full_name || item.email,
          };
        });
        setUsers(newUsers);
        const tmp = {...dataTableModel};
        tmp.rows = results.map((e) => renderTableRow(e, checkUser));
        setDatatable(tmp);
        setPaginationData((value) => ({
          ...value,
          counts: count,
          start,
          end,
          itemsPerPage: results?.length,
          ordering,
        }));
      },
      errorMessage: "Error getting users",
      onError: (err) => {
        console.log(err);
      },
      onFinally: () => setLoading(false),
    });
  };

  const getFilteredPage = () => {
    let filters = {search: searchQuery, page: 1};
    setPaginationData((value) => ({...value, currentPage: filters.page}));
    return filters;
  };

  const checkUser = (id, val) => {
    let values = checkedUsers;
    if (val) values.push(id);
    else values = values.filter((v) => v !== id);
    setCheckedUsers(values);
  };

  const getToUserDetail = () => {
    let user;
    if (data.targets[0]) {
      const fullName = data.targets[0].full_name.trim();
      const email = data.targets[0].email;
      user = fullName || email;
      console.log(user);

      return user;
    }
    return "All Users";
  };

  useEffect(() => {
    getAllUsers();
  }, [open]);

  useEffect(() => {
    const {search, page} = getFilteredPage();
    getAllUsers(search, page);
  }, [searchQuery, pageSize]);

  return (
    <Modal open={open} onClose={handleClose}>
      <MDBox
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          backgroundColor: "#282828",
          borderRadius: "10px",
          transform: "translate(-50%, -50%)",
          width: {xs: "90%", md: "600px"},
          boxShadow: 24,
        }}
      >
        <Formik
          innerRef={formikRef}
          initialValues={isEdit ? initialValues(data) : initialValues()}
          validationSchema={validationSchema}
          validateOnChange={false}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {(formik) => {
            return (
              <MDBox>
                <MDTypography pt={2} sx={{textAlign: "center"}}>
                  {isEdit ? "Edit Notification" : "Create Notification"}
                </MDTypography>
                <MDBox p={3}>
                  <Form>
                    <FormikInput
                      mb={1}
                      name={"title"}
                      label={"Notification Title"}
                      errors={formik.errors}
                    />
                    <FormikInput
                      mb={1}
                      name={"description"}
                      label={"Notification Message"}
                      errors={formik.errors}
                    />
                    <FormikInput
                      type={"autocomplete"}
                      fieldName={"targets"}
                      options={users}
                      accessKey={"label"}
                      otherProps={{disabled: isEdit}}
                      label={isEdit ? getToUserDetail() : "Notification to user"}
                      fullWidth
                      onChange={(value) => {
                        formik.setFieldValue("targets", [value?.id]);
                      }}
                      onInputChange={(value) => {
                        setSearchQuery(value);
                        getAllUsers({search: value});
                      }}
                    />
                    {/* <DataTable
                      loading={loading}
                      table={datatable}
                      currentPage={paginationData.currentPage}
                      numberOfItems={paginationData.counts}
                      numberOfItemsPage={paginationData.itemsPerPage}
                      start={paginationData.start}
                      end={paginationData.end}
                      searchFunc={getAllUsers}
                      searchQuery={searchQuery}
                      setPageSize={setPageSize}
                      pageSize={pageSize}
                      onPageChange={page => {
                        const { search } = getFilteredPage()
                        getAllUsers(search, page)
                        setPaginationData(value => ({ ...value, currentPage: page }))
                      }}
                    /> */}
                    <MDBox
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      gap={2}
                      pt={2}
                    >
                      <MDButton
                        color={"primary"}
                        onClick={() => {
                          formik.handleSubmit();
                        }}
                      >
                        Save
                      </MDButton>
                      <MDButton
                        variant={"outlined"}
                        onClick={() => {
                          handleClose();
                        }}
                      >
                        Cancel
                      </MDButton>
                    </MDBox>
                  </Form>
                </MDBox>
              </MDBox>
            );
          }}
        </Formik>
      </MDBox>
    </Modal>
  );
};
