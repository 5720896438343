import {Avatar} from "@mui/material";
import avatar from "../../assets/images/Ellipse.png";
import ActionsMenu from "../../components/ActionsMenu";
import info from "../../assets/icons/Info.svg";
import eye from "../../assets/icons/Eye.svg";
import checked from "../../assets/icons/checked.svg";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import React from "react";
import {linkRender} from "../../components/DataTableSimple/utils";


export const dataTableModel = {
  columns: [
    {Header: "Profile", accessor: "profile", width: 200,  disableOrdering: true},
    {Header: "Post URL", accessor: "post", disableOrdering: true},
    {Header: "Status", accessor: "status",  disableOrdering: true},
    {Header: "Type", accessor: "type",  disableOrdering: true},
    {Header: "Action", accessor: "action", disableOrdering: true},
  ],
  rows: [],
};

const popOver = (item, setItemId, setOpenFlagInput, setDataView, setOpenDeny, setOpenNeedDetail) => {
  const menuRender = (item) => {
    if(item.status === 'REJECTED'){
      return ([
        {
          title: 'Approve',
          src: checked,
          onClick: () => {
            setItemId(item.id)
            setDataView(item)
            setOpenFlagInput(true)
          }
        }
      ])
    } else if (item.status === 'APPROVED'){
      return (
        [{
          title: 'Deny',
          src: info,
          onClick: () => {
            setItemId(item.id)
            setOpenDeny(true)
          }
        }]
      )
    } else {
      return (
        [{
          title: 'Approve',
          src: checked,
          onClick: () => {
            setItemId(item.id)
            setDataView(item)
            setOpenFlagInput(true)
          }
        },
        {
          title: 'Deny',
          src: info,
          onClick: () => {
            setItemId(item.id)
            setOpenDeny(true)
          }
        }]
      )
    }
  }


  return (
    <>
      <ActionsMenu
        iconStyles={{ width: 20, height: 20 }}
        menuContent={
            [
              ...menuRender(item),
              {
                title: 'Need more details',
                src: eye,
                onClick: () => {
                  setItemId(item.id)
                  setDataView(item)
                  setOpenNeedDetail(true)
                }
              },

            ]
        }
      />
    </>
  )
}

const actionRender = (item, setOpenView, setEditMode, setDataView, setOpenConfirmDelete, setUserId, setOpenFlagInput, setOpenNeedDetail) => {
  return popOver(item, setOpenView, setEditMode, setDataView, setOpenConfirmDelete, setUserId, setOpenFlagInput, setOpenNeedDetail)
  // if(item.status === 'APPROVED'){
  //   return <MDBox sx={{marginLeft: 2 }} component={"img"} src={checked}/>
  // } else if (item.status === 'REJECTED') {
  //   return <MDBox sx={{marginLeft: 2, width: 28, height: 28 }} component={"img"} src={info}/>
  // } else {
  //   return popOver(item, setOpenView, setEditMode, setDataView, setOpenConfirmDelete, setUserId, setOpenFlagInput, setOpenNeedDetail)
  // }
}


export const renderTableRow = (item, setOpenView, setEditMode, setDataView, setOpenConfirmDelete, setUserId, setOpenFlagInput) => {
  item.profile =  <MDBox display={'flex'} alignItems={'center'} gap={1} >
    <Avatar src={item?.profile_picture} alt={avatar}/>
    <MDTypography variant={'subtitle2'}>{item?.full_name ?? 'No data'}</MDTypography>
  </MDBox>
  item.post = linkRender(item.post) ?? 'No data'
  item.status= item.status ?? 'No data'
  item.action = actionRender(item, setOpenView, setEditMode, setDataView, setOpenConfirmDelete, setUserId, setOpenFlagInput)
  return item
}
