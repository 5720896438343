import {date_fmt} from "../../services/helpers";
import ActionsMenu from "../../components/ActionsMenu";
import editPen from "../../assets/icons/editPen.svg";
import trashRed from "../../assets/icons/trash_red.svg";
import React from "react";
import pxToRem from "../../assets/theme/functions/pxToRem";
import * as Yup from "yup";

export const dataTableModel = {
  columns: [
    {Header: "Name", accessor: "name", width: `${pxToRem(200)}`},
    {Header: "Description", accessor: "description", width: `${pxToRem(320)}`},
    {Header: "Limit", accessor: "limit", width: `${pxToRem(80)}`},
    {Header: "Date", accessor: "date", width: `${pxToRem(80)}`},
    // {Header: "Date End", accessor: "date_end", width: `${pxToRem(80)}`},
    {Header: "Code", accessor: "code", width: `${pxToRem(80)}`},
    {Header: "Points", accessor: "points", width: `${pxToRem(80)}`},
    {Header: "Action", accessor: "action", width: `${pxToRem(50)}`},
  ],
  rows: [],
};
const REQUIRED_FIELD = "This field is required";
export const validationSchema = Yup.object().shape({
  name: Yup.string().required(REQUIRED_FIELD),
  description: Yup.string().required(REQUIRED_FIELD),
  limit: Yup.number(),
  date: Yup.date().required(REQUIRED_FIELD),
  code: Yup.mixed().required(REQUIRED_FIELD),
  points: Yup.string().required(REQUIRED_FIELD),
});

export const initialValues = (data) => {
  return {
    name: data?.name ? data.name : "",
    frequency: "DAILY",
    description: data?.description ? data.description : "",
    limit: data?.limit ? data.limit : "",
    date: data?.date ? data.date : "",
    code: data?.code ? {value: data.code, label: data.code} : {value: "", label: ""},
    points: data?.points ? data.points : "",
  };
};
export const TASKS = [
  {value: "SOCIAL_POST", label: "SOCIAL POST", id: 1},
  {value: "TEXT_REACH", label: "TEXT REACH", id: 2},
  {value: "REGISTER_AGENT", label: "REGISTER AGENT", id: 3},
  {value: "SUBMIT_LEAD", label: "SUBMIT LEAD", id: 4},
];
export const rowRender = (
  results,
  setOpen,
  setIsEdit,
  setTask,
  setTaskId,
  setIsDisabled,
  setOpenConfirmDelete
) => {
  return results?.map((d) => ({
    name: d.name ?? "No data",
    description: d.description ?? "No data",
    limit: d.limit ?? "No data",
    date: date_fmt(d.date, "MM/DD/YYYY") ?? "No data",
    code: d.code ? TASKS.find(t => t.value === d.code)?.label :  "No data",
    points: d.points ?? "No data",
    action: (
      <>
        <ActionsMenu
          menuContent={[
            {
              title: "Edit",
              src: editPen,
              onClick: () => {
                setOpen(true);
                setIsEdit(true);
                setTask(d);
                setTaskId(d.id);
                setIsDisabled(false);
              },
            },
            {
              title: "Delete",
              src: trashRed,
              onClick: () => {
                setOpenConfirmDelete(true);
                setTaskId(d.id);
              },
            },
          ]}
        />
      </>
    ),
  }));
};
