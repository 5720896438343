import AdminLayout from "../../components/AdminLayout";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import * as Yup from "yup";
import {useEffect, useRef, useState} from "react";
import MDButton from "../../components/MDButton";
import {Form, Formik} from "formik";
import FormikInput from "../../components/FormikInput";
import {Box} from "@mui/material";
import {wrapApiCall} from "../../services/helpers_ts";
import {useApi} from "../../services/helpers";
import user from 'assets/images/Ellipse.png';
import {PaginationCustom} from "../../components/DataTableSimple/PaginationCustom";
import SearchBar from "../../components/SearchBar";

export const validationSchema = Yup.object().shape({
  response: Yup.string(),
})

export const initialValues = {
 response: '',
};

export const AdminFeedback = () => {
  const api = useApi()
  const formikRef = useRef();
  const [loading, setLoading] = useState(false);
  const [replyInput, setReplayInput] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [feedback, setFeedback] = useState([]);
  const [pageSize, setPageSize] = useState(4);
  const [paginationData, setPaginationData] = useState({
    counts: 0,
    itemsPerPage: 0,
    currentPage: 1,
    ordering: ""
  });

  const sendReplay = (id, data) => {
    setLoading(true)
    api.sendResponseToFeedBack(id, data).handle({
      onSuccess: () => {
        getFeedBack({search: searchQuery});
        setLoading(false)
      },
      errorMessage: 'Sorry an error occurred',
      successMessage: 'Response sent'

    })
  }

  const getFeedBack = (search) => {
    setLoading(true)
    api.getFeedBack({...search, page_size: pageSize}).handle({
      onSuccess: (result) => {
        const {count, end, start, results} = result?.data
        setLoading(false)
        setFeedback(results)
        setPaginationData(value => ({...value, counts: count, start: start, end: end, itemsPerPage: results?.length}))
      },
      errorMessage: 'No data found',
    })
  }
  const getFilteredPage = () =>{
    let filters =  {search: searchQuery, page: 1}
    setPaginationData(value => ({...value, currentPage: filters.page}))
    return filters
  }

  const handleSubmit = () => {
    formikRef?.current?.handleSubmit()
    setReplayInput(null)
  }

  useEffect(() => {
    getFeedBack({search: searchQuery, page: paginationData.currentPage});
  }, [searchQuery, pageSize])


return (
    <AdminLayout
      title={'Feedback'}
    >
      <MDBox width={'100%'} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"flex-start"}>
        <SearchBar loading={loading} setSearchQuery={setSearchQuery}/>
        <MDBox width={'100%'} display={'flex'} flexDirection={'column'} gap={2} mt={2}>
          {feedback?.length ? feedback.map(item => {
              return (
                <MDBox key={`id-${item.id}`} width={"100%"}>
                  <MDBox width={"100%"} bgColor={'#282828'} borderRadius={'md'} px={2}>
                    <MDBox display={"flex"} justifyContent={"space-between"} width={'100%'}>
                      <MDBox display={"flex"} borderBottom={'1px solid #858585'} width={'100%'} alignItems={"center"} justifyContent={"space-between"} py={2}>
                        <MDBox display={'flex'} alignItems={'center'} gap={1}>
                          <Box component={'img'} src={item.user?.profile_picture ? item.user?.profile_picture : user} alt={'guy'} borderRadius={'50%'} width={42} height={42} />
                          <MDBox>
                            <MDTypography variant={'subtitle2'}>
                              {item.user.username}
                            </MDTypography>
                            <MDTypography
                              variant={'subtitle2'}
                            >
                              {item.user.email}
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                        <MDButton
                          variant={item.status === 'RES' ? 'text': 'contained'}
                          color={'primary'}
                          sx={{ height: 30, minHeight: 30, width: 104, textTransform: 'capitalize' }}
                          onClick={() => item.status === 'RES' ? '' : setReplayInput(item.id)}
                        >
                          {item.status === 'RES' ? 'Replied': 'Reply'}
                        </MDButton>
                      </MDBox>
                    </MDBox>
                    <MDBox py={2} display={'flex'} gap={1}>
                      <MDTypography
                        variant={'subtitle2'}
                        color={'primary'}
                      >
                        Subject:
                      </MDTypography>
                      <MDTypography
                        variant={'subtitle2'}
                      >
                        {item.subject}
                      </MDTypography>
                    </MDBox>
                    <MDTypography
                      variant={'subtitle2'}
                      pb={2}
                    >
                      {item.text}
                    </MDTypography>
                  </MDBox>
                  {replyInput === item.id &&
                    <MDBox bgColor={'#282828'} p={2} borderRadius={'md'} mt={0.5}>
                      <Formik
                        innerRef={formikRef}
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        validateOnBlur={false}
                        onSubmit={values => sendReplay(item?.id, values)}
                      >
                        {(formik)=> (
                          <Form>
                            <FormikInput name={'response'} type={'textarea'} rows={3} placeholder={'Write a response here...'} errors={formik.errors}/>
                            <MDBox display={'flex'} justifyContent={'flex-end'} gap={2}>
                              <MDButton
                                loading={loading}
                                color={'primary'}
                                sx={{ height: 30, minHeight: 30, width: 104, textTransform: 'capitalize'}}
                                onClick={() => handleSubmit()}
                              >
                                Send
                              </MDButton>
                              <MDButton
                                variant={'outlined'}
                                color={'primary'}
                                sx={{ height: 30, minHeight: 30, width: 104, textTransform: 'capitalize' }}
                                onClick={() => setReplayInput(null)}
                              >
                                Cancel
                              </MDButton>
                            </MDBox>
                          </Form>
                        )}
                      </Formik>
                    </MDBox>
                  }
                </MDBox>

              )
            }) :
            <MDBox display={'flex'} alignItems={'center'} justifyContent={'center'} minHeight={'50vh'} width={'100%'}>
              <MDTypography variant={'subtitle1'}>No data found</MDTypography>
            </MDBox>
          }
        </MDBox>
        <MDBox width={'100%'} height={80}>
          <PaginationCustom
            currentPage={paginationData.currentPage}
            totalCount={paginationData.counts}
            startPage={paginationData.start}
            endPage={paginationData.end}
            setPageSize={setPageSize}
            pageSize={pageSize}
            onPageChange={page => {
              getFeedBack({ ...getFilteredPage(), page:page})
              setPaginationData(value => ({...value, currentPage: page}))
            }}
          />

        </MDBox>
      </MDBox>

    </AdminLayout>
  );
}
