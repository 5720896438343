import MDBox from "../../components/MDBox";
import {Form, Formik} from "formik";
import MDTypography from "../../components/MDTypography";
import Autocomplete from "@mui/material/Autocomplete";
import MDInput from "../../components/MDInput";
import FormikInput from "../../components/FormikInput";
import MDButton from "../../components/MDButton";
import {Modal} from "@mui/material";
import {useEffect, useRef, useState} from "react";
import * as Yup from "yup";


const validationSchema = Yup.object().shape({
  email: Yup.string().email().required('An email is required'),
  password: Yup.string().required('A password is required'),
})


function CreateAdminModal({handleClose, open, handleSubmit}) {
  const formikRef = useRef();

  const initialValues = {
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    username: ''
  };


  return (
    <Modal open={open} onClose={handleClose}>
      <MDBox sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        backgroundColor: '#282828',
        borderRadius: '10px',
        transform: 'translate(-50%, -50%)',
        boxShadow: 24,
        width:{xs:'90%', md:'auto'},

      }}>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange={false}
          onSubmit={values => {
            handleSubmit(values)
          }}
        >
          {(formik) => {
            return (
              <MDBox>
                <MDTypography pt={2}
                              sx={{textAlign: 'center'}}>Create New Admin Account</MDTypography>
                <MDBox p={3}>
                  <Form>
                    <MDBox>
                    </MDBox>
                    <FormikInput mb={1} name={'first_name'} label={'First Name'} errors={formik.errors.first_name}/>
                    <FormikInput mb={1} name={'last_name'} label={'Last Name'} errors={formik.errors.last_name}/>
                    <FormikInput mb={1} name={'email'} label={'Email'} errors={formik.errors.email}/>
                    <FormikInput mb={1} type={"password"} name={'password'} label={'Password'} errors={formik.errors.password}/>
                    <MDBox display={'flex'} justifyContent={'center'} alignItems={'center'} gap={2} pt={2}>
                        <MDButton color={'primary'} onClick={() => {
                          formik.setFieldValue('username', formik.values.email)
                          formik.handleSubmit()
                        }}>Submit</MDButton>
                      <MDButton variant={'outlined'} onClick={() => {
                        handleClose()
                      }}>Cancel</MDButton>
                    </MDBox>
                  </Form>
                </MDBox>
              </MDBox>
            )
          }}
        </Formik>
      </MDBox>
    </Modal>
  )
}

export default CreateAdminModal
