import { createSvgIcon } from "@mui/material";
import React from "react";

export const Bell = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.82993 8.93751C4.8288 8.12214 4.98905 7.31461 5.30146 6.56147C5.61387 5.80832 6.07225 5.12446 6.65019 4.54929C7.22813 3.97413 7.91419 3.51904 8.66883 3.21026C9.42347 2.90148 10.2318 2.74512 11.0471 2.75018C14.4494 2.77547 17.1707 5.60342 17.1707 9.01518V9.62501C17.1707 12.7029 17.8147 14.489 18.3818 15.4652C18.4429 15.5695 18.4754 15.688 18.4761 15.8089C18.4767 15.9298 18.4455 16.0487 18.3855 16.1537C18.3255 16.2586 18.2389 16.3459 18.1345 16.4067C18.03 16.4675 17.9113 16.4997 17.7904 16.5H4.20952C4.08862 16.4997 3.96994 16.4675 3.86546 16.4067C3.76098 16.3459 3.67438 16.2586 3.61441 16.1536C3.55443 16.0486 3.52321 15.9297 3.52388 15.8088C3.52454 15.6879 3.55708 15.5693 3.61821 15.465C4.18568 14.4888 4.82993 12.7028 4.82993 9.62501L4.82993 8.93751Z" stroke="#current" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.25 16.5V17.1875C8.25 17.9168 8.53973 18.6163 9.05546 19.132C9.57118 19.6478 10.2707 19.9375 11 19.9375C11.7293 19.9375 12.4288 19.6478 12.9445 19.132C13.4603 18.6163 13.75 17.9168 13.75 17.1875V16.5" stroke="#current" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>,
  'Bell',
);
export const Broadcast = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 13.75C12.5188 13.75 13.75 12.5188 13.75 11C13.75 9.48122 12.5188 8.25 11 8.25C9.48122 8.25 8.25 9.48122 8.25 11C8.25 12.5188 9.48122 13.75 11 13.75Z" stroke="current" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6.62477 15.3752C6.04958 14.801 5.59325 14.119 5.28191 13.3683C4.97056 12.6175 4.8103 11.8127 4.8103 11C4.8103 10.1872 4.97056 9.38246 5.28191 8.63171C5.59325 7.88096 6.04958 7.19897 6.62477 6.62476" stroke="current" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M15.3752 6.62476C15.9504 7.19897 16.4068 7.88096 16.7181 8.63171C17.0295 9.38246 17.1897 10.1872 17.1897 11C17.1897 11.8127 17.0295 12.6175 16.7181 13.3683C16.4068 14.119 15.9504 14.801 15.3752 15.3752" stroke="current" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4.19409 17.8059C3.29934 16.9127 2.58951 15.8518 2.10519 14.684C1.62087 13.5161 1.37158 12.2643 1.37158 11C1.37158 9.73572 1.62087 8.48385 2.10519 7.31601C2.58951 6.14818 3.29934 5.08731 4.19409 4.19409" stroke="current" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M17.8059 4.19409C18.7007 5.08731 19.4105 6.14818 19.8948 7.31601C20.3791 8.48385 20.6284 9.73572 20.6284 11C20.6284 12.2643 20.3791 13.5161 19.8948 14.684C19.4105 15.8518 18.7007 16.9127 17.8059 17.8059" stroke="current" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>,
  'Broadcast',
);
export const Folders = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.1875 9.625V17.2639C17.1875 17.426 17.1231 17.5814 17.0085 17.696C16.8939 17.8106 16.7385 17.875 16.5764 17.875H3.4375C3.25516 17.875 3.0803 17.8026 2.95136 17.6736C2.82243 17.5447 2.75 17.3698 2.75 17.1875V7.5625C2.75 7.38016 2.82243 7.2053 2.95136 7.07636C3.0803 6.94743 3.25516 6.875 3.4375 6.875H7.33333C7.48209 6.875 7.62683 6.92325 7.74583 7.0125L10.1292 8.8C10.2482 8.88925 10.3929 8.9375 10.5417 8.9375H16.5C16.6823 8.9375 16.8572 9.00993 16.9861 9.13886C17.1151 9.2678 17.1875 9.44266 17.1875 9.625Z" stroke="current" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.5 6.875V4.8125C5.5 4.63016 5.57243 4.4553 5.70136 4.32636C5.8303 4.19743 6.00516 4.125 6.1875 4.125H10.0833C10.2321 4.125 10.3768 4.17325 10.4958 4.2625L12.8792 6.05C12.9982 6.13925 13.1429 6.1875 13.2917 6.1875H19.25C19.4323 6.1875 19.6072 6.25993 19.7361 6.38886C19.8651 6.5178 19.9375 6.69266 19.9375 6.875V14.5139C19.9375 14.5941 19.9217 14.6736 19.891 14.7478C19.8603 14.8219 19.8153 14.8893 19.7585 14.946C19.7018 15.0028 19.6344 15.0478 19.5603 15.0785C19.4861 15.1092 19.4066 15.125 19.3264 15.125H17.1875" stroke="current" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>,
  'Folders',
);
export const Info = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 19.25C15.5563 19.25 19.25 15.5563 19.25 11C19.25 6.44365 15.5563 2.75 11 2.75C6.44365 2.75 2.75 6.44365 2.75 11C2.75 15.5563 6.44365 19.25 11 19.25Z" stroke="current" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10.3125 10.3125H11V15.125H11.6875" stroke="current" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11 8.25C11.5695 8.25 12.0312 7.78829 12.0312 7.21875C12.0312 6.64921 11.5695 6.1875 11 6.1875C10.4305 6.1875 9.96875 6.64921 9.96875 7.21875C9.96875 7.78829 10.4305 8.25 11 8.25Z" fill="#f1f1f1" />
  </svg>,
  'Info',
);

export const PaperPlaneTilt = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.0684 3.08325L2.05586 7.59961C1.92115 7.6376 1.80125 7.71581 1.71218 7.82378C1.62311 7.93174 1.56911 8.06432 1.5574 8.20379C1.5457 8.34327 1.57684 8.483 1.64667 8.6043C1.7165 8.7256 1.82169 8.8227 1.94818 8.88261L9.30537 12.3676C9.44886 12.4356 9.5644 12.5511 9.63238 12.6946L13.1174 20.0518C13.1773 20.1783 13.2744 20.2835 13.3957 20.3533C13.517 20.4231 13.6567 20.4543 13.7962 20.4426C13.9356 20.4309 14.0682 20.3769 14.1762 20.2878C14.2842 20.1987 14.3624 20.0788 14.4004 19.9441L18.9167 3.93156C18.9499 3.81394 18.9511 3.6896 18.9203 3.57135C18.8894 3.4531 18.8276 3.34521 18.7412 3.2588C18.6548 3.17238 18.5469 3.11056 18.4286 3.07971C18.3104 3.04885 18.186 3.05007 18.0684 3.08325Z" stroke="current" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9.52728 12.4727L13.4164 8.58362" stroke="current" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>,
  'PaperPlaneTilt',
);

export const Upload = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.125 11H19.25C19.4323 11 19.6072 11.0724 19.7361 11.2014C19.8651 11.3303 19.9375 11.5052 19.9375 11.6875V17.1875C19.9375 17.3698 19.8651 17.5447 19.7361 17.6736C19.6072 17.8026 19.4323 17.875 19.25 17.875H2.75C2.56766 17.875 2.3928 17.8026 2.26386 17.6736C2.13493 17.5447 2.0625 17.3698 2.0625 17.1875V11.6875C2.0625 11.5052 2.13493 11.3303 2.26386 11.2014C2.3928 11.0724 2.56766 11 2.75 11H6.875" stroke="current" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11 11V2.0625" stroke="current" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6.875 6.1875L11 2.0625L15.125 6.1875" stroke="current" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M16.1562 15.4688C16.7258 15.4688 17.1875 15.007 17.1875 14.4375C17.1875 13.868 16.7258 13.4062 16.1562 13.4062C15.5867 13.4062 15.125 13.868 15.125 14.4375C15.125 15.007 15.5867 15.4688 16.1562 15.4688Z" fill="#F1F1F1" />
  </svg>,
  'Upload',
);

export const Users = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.5625 13.75C10.0305 13.75 12.0312 11.7493 12.0312 9.28125C12.0312 6.81323 10.0305 4.8125 7.5625 4.8125C5.09448 4.8125 3.09375 6.81323 3.09375 9.28125C3.09375 11.7493 5.09448 13.75 7.5625 13.75Z" stroke="current" strokeWidth="1.5" strokeMiterlimit="10" />
    <path d="M13.3558 4.97895C13.9704 4.80577 14.615 4.76632 15.2462 4.86325C15.8774 4.96018 16.4805 5.19125 17.0148 5.54088C17.5492 5.89051 18.0024 6.35059 18.344 6.89013C18.6855 7.42967 18.9075 8.03614 18.995 8.6687C19.0824 9.30125 19.0333 9.9452 18.8509 10.5572C18.6685 11.1692 18.3571 11.7349 17.9377 12.2164C17.5182 12.6979 17.0004 13.0839 16.4192 13.3485C15.838 13.613 15.2069 13.7499 14.5683 13.75" stroke="current" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1.37469 16.9638C2.07262 15.971 2.99916 15.1608 4.0761 14.6014C5.15303 14.0421 6.34874 13.75 7.56227 13.75C8.7758 13.75 9.97153 14.0419 11.0485 14.6012C12.1255 15.1604 13.0521 15.9706 13.7501 16.9633" stroke="current" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14.5683 13.75C15.7819 13.7491 16.9779 14.0407 18.055 14.6C19.1321 15.1594 20.0585 15.97 20.7558 16.9633" stroke="current" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>,
  'Users',
);

export const Wallet = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.4375 5.5V16.5C3.4375 16.8647 3.58237 17.2144 3.84023 17.4723C4.09809 17.7301 4.44783 17.875 4.8125 17.875H18.5625C18.7448 17.875 18.9197 17.8026 19.0486 17.6736C19.1776 17.5447 19.25 17.3698 19.25 17.1875V7.5625C19.25 7.38016 19.1776 7.2053 19.0486 7.07636C18.9197 6.94743 18.7448 6.875 18.5625 6.875H4.8125C4.44783 6.875 4.09809 6.73013 3.84023 6.47227C3.58237 6.21441 3.4375 5.86467 3.4375 5.5ZM3.4375 5.5C3.4375 5.13533 3.58237 4.78559 3.84023 4.52773C4.09809 4.26987 4.44783 4.125 4.8125 4.125H16.5" stroke="current" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M15.4688 13.4062C16.0383 13.4062 16.5 12.9445 16.5 12.375C16.5 11.8055 16.0383 11.3438 15.4688 11.3438C14.8992 11.3438 14.4375 11.8055 14.4375 12.375C14.4375 12.9445 14.8992 13.4062 15.4688 13.4062Z" fill="#F1F1F1" />
  </svg>,
  'Wallet',
);
export const CurrencyCircleDollar = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.4375 5.5V16.5C3.4375 16.8647 3.58237 17.2144 3.84023 17.4723C4.09809 17.7301 4.44783 17.875 4.8125 17.875H18.5625C18.7448 17.875 18.9197 17.8026 19.0486 17.6736C19.1776 17.5447 19.25 17.3698 19.25 17.1875V7.5625C19.25 7.38016 19.1776 7.2053 19.0486 7.07636C18.9197 6.94743 18.7448 6.875 18.5625 6.875H4.8125C4.44783 6.875 4.09809 6.73013 3.84023 6.47227C3.58237 6.21441 3.4375 5.86467 3.4375 5.5ZM3.4375 5.5C3.4375 5.13533 3.58237 4.78559 3.84023 4.52773C4.09809 4.26987 4.44783 4.125 4.8125 4.125H16.5" stroke="current" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M15.4688 13.4062C16.0383 13.4062 16.5 12.9445 16.5 12.375C16.5 11.8055 16.0383 11.3438 15.4688 11.3438C14.8992 11.3438 14.4375 11.8055 14.4375 12.375C14.4375 12.9445 14.8992 13.4062 15.4688 13.4062Z" fill="#F1F1F1" />
  </svg>,
  'Wallet',
);
export const ChatCircleDots = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.90404 15.2108C2.87903 13.4829 2.52006 11.4402 2.89454 9.46633C3.26902 7.49248 4.35119 5.72322 5.93784 4.49079C7.52448 3.25836 9.50646 2.64753 11.5116 2.77301C13.5167 2.89848 15.4071 3.75163 16.8277 5.17225C18.2484 6.59287 19.1015 8.48323 19.227 10.4884C19.3525 12.4935 18.7417 14.4755 17.5092 16.0621C16.2768 17.6488 14.5076 18.731 12.5337 19.1055C10.5599 19.48 8.5172 19.121 6.78928 18.096L6.7893 18.0959L3.93991 18.91C3.82202 18.9437 3.69727 18.9453 3.57858 18.9145C3.45989 18.8837 3.35159 18.8218 3.26489 18.7351C3.1782 18.6484 3.11626 18.5401 3.08551 18.4214C3.05476 18.3027 3.0563 18.178 3.08998 18.0601L3.9041 15.2107L3.90404 15.2108Z" stroke="#current" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11 12.0312C11.5695 12.0312 12.0312 11.5695 12.0312 11C12.0312 10.4305 11.5695 9.96875 11 9.96875C10.4305 9.96875 9.96875 10.4305 9.96875 11C9.96875 11.5695 10.4305 12.0312 11 12.0312Z" fill="#F1F1F1" />
    <path d="M6.875 12.0312C7.44454 12.0312 7.90625 11.5695 7.90625 11C7.90625 10.4305 7.44454 9.96875 6.875 9.96875C6.30546 9.96875 5.84375 10.4305 5.84375 11C5.84375 11.5695 6.30546 12.0312 6.875 12.0312Z" fill="#F1F1F1" />
    <path d="M15.125 12.0312C15.6945 12.0312 16.1562 11.5695 16.1562 11C16.1562 10.4305 15.6945 9.96875 15.125 9.96875C14.5555 9.96875 14.0938 10.4305 14.0938 11C14.0938 11.5695 14.5555 12.0312 15.125 12.0312Z" fill="#F1F1F1" />
  </svg>,
  'ChatCircleDots',
);
export const SignOut = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.954 7.39062L18.5625 11L14.954 14.6094" stroke="#current" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.9375 11H18.56" stroke="#current" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.9375 18.5625H4.125C3.94266 18.5625 3.7678 18.4901 3.63886 18.3611C3.50993 18.2322 3.4375 18.0573 3.4375 17.875V4.125C3.4375 3.94266 3.50993 3.7678 3.63886 3.63886C3.7678 3.50993 3.94266 3.4375 4.125 3.4375H8.9375" stroke="#current" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>,
  'SignOut',
);
export const ChartPieSlice = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 11V2.75" stroke="#current" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M18.1447 6.875L3.85529 15.125" stroke="#current" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M2.88757 12.5082C2.53038 10.5752 2.87405 8.57809 3.85687 6.87562C4.83968 5.17315 6.39726 3.87684 8.24989 3.21948V9.41232L2.88757 12.5082Z" stroke="#current" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11 2.75C12.4454 2.75027 13.8654 3.13028 15.1177 3.85198C16.3701 4.57368 17.4108 5.61175 18.1358 6.86222C18.8607 8.11269 19.2444 9.53167 19.2484 10.9771C19.2525 12.4225 18.8767 13.8436 18.1587 15.0981C17.4407 16.3525 16.4057 17.3964 15.1574 18.125C13.9091 18.8537 12.4912 19.2416 11.0458 19.2499C9.60044 19.2582 8.17824 18.8866 6.92164 18.1723C5.66504 17.458 4.61815 16.4262 3.8858 15.18" stroke="#current" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>,
  'ChartPieSlice',
);
export const Tasks = createSvgIcon(
  <svg width="20" height="20" viewBox="0 0 23 25" fill="#fff" xmlns="http://www.w3.org/2000/svg">
    <path id="Shape" fillRule="evenodd" clipRule="evenodd" d="M20.44 2.50002H15.0969C14.573 1.05 13.1675 0 11.5 0C9.83247 0 8.4269 1.05 7.90305 2.50002H2.55557C1.14365 2.50002 0 3.61875 0 5.00004V22.5C0 23.8812 1.14359 25 2.55557 25H20.4444C21.8564 25 23 23.8813 23 22.5V4.99998C22.9999 3.61875 21.8564 2.50002 20.4444 2.50002ZM11.5 2.50002C12.2027 2.50002 12.7777 3.05625 12.7777 3.75C12.7777 4.44375 12.2027 4.99998 11.5 4.99998C10.7972 4.99998 10.2222 4.44375 10.2222 3.75C10.2222 3.05625 10.7972 2.50002 11.5 2.50002ZM3.83332 15L8.94446 20L19.1667 10L17.3586 8.23124L8.94446 16.4625L5.64139 13.2312L3.83332 15Z" stroke="#current" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>,
  'Tasks',
);
export const ReferralIcon = createSvgIcon(
  <svg width="20" height="20" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.33691 14.9016L14.6702 14.9016"
      stroke="#919191"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="white"
    />
    <path
      d="M6.50391 2.90145V14.9014L9.50391 14.9014V2.90145C9.50391 2.16812 9.20391 1.56812 8.30391 1.56812L7.70391 1.56812C6.80391 1.56812 6.50391 2.16812 6.50391 2.90145Z"
      stroke="#919191"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="white"
    />
    <path
      d="M2.00391 6.90145V14.9014L4.67057 14.9014V6.90145C4.67057 6.16812 4.40391 5.56812 3.60391 5.56812L3.07057 5.56812C2.27057 5.56812 2.00391 6.16812 2.00391 6.90145Z"
      stroke="#919191"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="white"
    />
    <path
      d="M11.3369 10.2349V14.9016H14.0036V10.2349C14.0036 9.50161 13.7369 8.90161 12.9369 8.90161H12.4036C11.6036 8.90161 11.3369 9.50161 11.3369 10.2349Z"
      stroke="#919191"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="white"
    />
  </svg>,
  "ReferralIcon"
);
