import AdminLayout from "../../components/AdminLayout";
import MDBox from "../../components/MDBox";
import React, {useEffect, useRef, useState} from "react";
import {useApi} from "../../services/helpers";
import DataTableSimple from "../../components/DataTableSimple";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";
import SearchBar from "../../components/SearchBar";
import MDButton from "../../components/MDButton";
import MDTypography from "../../components/MDTypography";
import FormikInput from "../../components/FormikInput";
import {BaseModal} from "../../components/BaseModal";
import {Form, Formik} from "formik";
import {dataTableModel, initialValues, rowRender, TASKS, validationSchema} from "./utils";
import moment from "moment";

export const AdminDailyTasks = () => {
  const api = useApi();
  const formikRef = useRef();
  const [loading, setLoading] = useState(false);
  const [table, setTable] = useState(dataTableModel);
  const [paginationData, setPaginationData] = useState({
    counts: 0,
    itemsPerPage: 0,
    currentPage: 1,
  });
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [open, setOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [task, setTask] = useState(null);
  const [taskId, setTaskId] = useState(null);
  const [value, setValue] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const today = new Date();

  const handleCloseTaskModal = () => {
    setValue(null);
    setOpen(false);
  };
  const handleCloseConfirmation = () => {
    setOpenConfirmation(false);
  };
  const handleConfirmAction = () => {
    setOpenConfirmation(false);
  };
  const handleConfirmDelete = () => {
    setOpenConfirmDelete(false);
    DeleteTask(taskId);
  };

  const getAllTasks = () => {
    setLoading(true);
    api.getTasks().handle({
      onSuccess: (result) => {
        const results = result?.data;
        setTable({
          ...dataTableModel,
          rows: rowRender(
            results,
            setOpen,
            setIsEdit,
            setTask,
            setTaskId,
            setIsDisabled,
            setOpenConfirmDelete
          ),
        });
        setPaginationData({
          counts: results.length,
          itemsPerPage: results.length,
          currentPage: 1,
        });
      },
      errorMessage: "Error getting tasks",
      onError: (err) => {
        console.log(err);
      },
      onFinally: () => setLoading(false),
    });
  };

  const CreateTask = (values) => {
    const formattedValue = {...values, code: values.code.value, date_end: values.date_start};
    api.createTask(formattedValue).handle({
      onSuccess: () => {
        getAllTasks();
        setOpen(false);
        setValue(null);
      },
      errorMessage: "Error creating task",
      successMessage: "Task created successfully",
    });
  };

  const UpdateTask = (id, values) => {
    const formattedValue = {...values, code: values.code.value, date_end: values.date_start};
    api.updateTask(id, formattedValue).handle({
      onSuccess: () => {
        getAllTasks();
        setOpen(false);
        setValue(null);
      },
      errorMessage: "Error updating task",
      successMessage: "Task updated successfully",
    });
  };

  const DeleteTask = (id) => {
    api.deleteTask(id).handle({
      onSuccess: () => {
        getAllTasks();
      },
      errorMessage: "Error deleting task",
      successMessage: "Task deleted successfully",
    });
  };

  useEffect(() => {
    getAllTasks();
  }, [searchQuery]);

  return (
    <AdminLayout title={"Daily Tasks"}>
      <MDBox width={"100%"}>
        <MDBox
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          py={2}
        >
          <SearchBar loading={loading} search={getAllTasks} setSearchQuery={setSearchQuery} />
          <MDButton
            color={"primary"}
            sx={{height: "44px", borderRadius: "10px"}}
            onClick={() => {
              setIsEdit(false);
              setOpen(true);
            }}
          >
            CREATE TASK
          </MDButton>
        </MDBox>
        <MDBox>
          <DataTableSimple table={table} />
        </MDBox>
      </MDBox>
      <BaseModal
        open={open}
        handleClose={() => {
          setIsEdit(false);
          setOpen(true);
        }}
      >
        <Formik
          innerRef={formikRef}
          initialValues={initialValues(isEdit ? task : {})}
          validationSchema={validationSchema}
          validateOnChange={false}
          enableReinitialize={true}
          onSubmit={(values) => {
            isEdit ? UpdateTask(taskId, values) : CreateTask(values);
          }}
        >
          {(formik) => {
            return (
              <MDBox>
                <MDTypography pt={2} sx={{textAlign: "center"}}>
                  {isEdit ? "Update Task" : "Create Task"}
                </MDTypography>
                <MDBox p={3}>
                  <Form>
                    <MDBox>
                      <FormikInput
                        fieldName={"code"}
                        label={"Select a Task"}
                        disabled={isDisabled}
                        type={"autocomplete"}
                        value={formik.values.code}
                        accessKey={"label"}
                        options={TASKS}
                        onChange={(value) => {
                          formik.setFieldValue("code", value);
                        }}
                        errors={formik.errors}
                      />
                    </MDBox>
                    <MDBox my={2}>
                      <FormikInput
                        name={"name"}
                        label={"Name"}
                        disabled={isDisabled}
                        errors={formik.errors.title}
                      />
                    </MDBox>
                    <MDBox my={2}>
                      <FormikInput
                        name={"description"}
                        label={"Description"}
                        disabled={isDisabled}
                        errors={formik.errors}
                      />
                    </MDBox>
                    <MDBox my={2}>
                      <FormikInput
                        name={"limit"}
                        label={"Limit"}
                        type={"number"}
                        disabled={isDisabled}
                        errors={formik.errors}
                      />
                    </MDBox>
                    <MDBox my={2}>
                      <FormikInput
                        type={"date"}
                        setFieldValue={formik.setFieldValue}
                        name={"date"}
                        label={"Date"}
                        disabled={isDisabled}
                        errorText={formik.errors.date}
                        extraParams={{
                          minDate: moment(today, "YYYY-MM-DD"),
                          clearable: true,
                          sx: {
                            width: "100%",
                            ".MuiInputBase-input": {
                              color: formik.values.date ? "#fff" : "",
                            },
                          },
                        }}
                      />
                    </MDBox>
                    <FormikInput
                      name={"points"}
                      label={"Points"}
                      type={"number"}
                      disabled={isDisabled}
                      errors={formik.errors}
                    />
                    <MDBox
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      gap={2}
                      pt={2}
                    >
                      <MDButton
                        disabled={isDisabled || loading}
                        loading={loading}
                        color={"primary"}
                        sx={{minWidth: 97}}
                        onClick={formikRef?.current?.handleSubmit}
                      >
                        Submit
                      </MDButton>
                      <MDButton variant={"outlined"} onClick={handleCloseTaskModal}>
                        Cancel
                      </MDButton>
                    </MDBox>
                  </Form>
                </MDBox>
              </MDBox>
            );
          }}
        </Formik>
      </BaseModal>
      <ConfirmModal
        handleClose={() => setOpenConfirmDelete(false)}
        open={openConfirmDelete}
        handleConfirm={handleConfirmDelete}
        title={"Are you sure?"}
        subtitle={"Are you sure you want to delete this task?"}
      />
      <ConfirmModal
        handleClose={handleCloseConfirmation}
        open={openConfirmation}
        handleConfirm={handleConfirmAction}
        title={"Are you sure?"}
        subtitle={"Are you sure you want to proceed?"}
      />
    </AdminLayout>
  );
};
