import MDBox from "../../components/MDBox";
import {Form, Formik} from "formik";
import MDTypography from "../../components/MDTypography";
import FormikInput from "../../components/FormikInput";
import MDButton from "../../components/MDButton";
import {Grid, Modal} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import * as Yup from "yup";
import {useApi} from "../../services/helpers";


export const CreateEditTemplateModal = ({handleClose, open, data, title, handleSubmit}) => {
  const formikRef = useRef();
  const [isViewDetails, setIsViewDetails] = useState(false);
  const [loading, setLoading] = useState(false);
  const api = useApi()
  const [language, setLanguage] = useState([]);
  const [languageArray, setLanguageArray] = useState([]);

  const TEMPLATES = [
    {
      id: 1,
      title: 'From username',
      value: '{from_username}'
    },
    {
      id: 2,
      title: 'From email',
      value: '{from_email}'
    },
    {
      id: 3,
      title: 'From phone',
      value: '{from_phone}'
    },
    {
      id: 4,
      title: 'To contact name',
      value: '{to_contact_name}'
    },
    {
      id: 5,
      title: 'To email',
      value: '{to_email}'
    },
    {
      id: 6,
      title: 'To phone',
      value: '{to_phone}'
    },
  ]

  const initialValues = (data) => {
    return {
      message: data?.message ? data.message : ''
    }};

  const validationSchema = Yup.object().shape({
    message: Yup.string().required('Message is required'),
    language: Yup.mixed().required('Language is required'),
  })

  const getLanguages = () => {
    setLoading(true)
    api.getLanguages({}).handle({
      onSuccess: (result) => {
        const languagesBackend = result?.data
        setLanguageArray(Object.values(languagesBackend));
        setLoading(false)
      },
      errorMessage: 'Error getting Languages',
      onFinally: () => setLoading(false)
    })
  }

  useEffect(() => {
    getLanguages()
  }, []);

  return (
    <Modal open={open} onClose={handleClose} onKeyUp={(e) => {
      if (e.key === "Enter") {
        handleClose();
      }
    }}>
      <MDBox sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        backgroundColor: '#282828',
        borderRadius: '10px',
        transform: 'translate(-50%, -50%)',
        boxShadow: 24,
        width:{xs:'90%', md:'auto'},
      }}>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues(data)}
          validationSchema={validationSchema}
          validateOnChange={false}
          onSubmit={values => {
            handleSubmit(values)
            handleClose()
          }}
        >
          {(formik) => {
            return (
              <MDBox>
                <MDTypography pt={2} sx={{textAlign: 'center'}}>{title}</MDTypography>
                <MDBox p={3}>
                  <Form>
                    <Grid container justifyContent={"space-between"} gap={1} marginBottom={3}>
                      {TEMPLATES.map(item => (<Grid maxWidth={'114px'} item ><MDButton sx={{width:'114px', height:'46px'}} key={`id-${item.id}`} color={'primary'} onClick={() => formik.setFieldValue('message', `${formik.values.message} ${item.value}`)} size={'small'}>{item.title}</MDButton></Grid>))}
                    </Grid>
                    <FormikInput mb={1} name={'message'} label={'Message'} type={"textarea"} errors={formik.errors}/>
                    <FormikInput
                        fieldName={"language"}
                        label={"Select a Language"}
                        readOnly={isViewDetails}
                        value={formik.values.language}
                        type={'autocomplete'}
                        accessKey={"name"}
                        options={languageArray}
                        onChange={e => {
                          setLanguage(e)
                          formik.setFieldValue('language', e)
                        }}
                    />
                    <MDBox display={'flex'} justifyContent={'center'} alignItems={'center'} gap={2} pt={2}>
                      <MDButton color={'primary'} onClick={() => {formik.handleSubmit()}}>Submit</MDButton>
                      <MDButton variant={'outlined'} onClick={() => handleClose()}>Cancel</MDButton>
                    </MDBox>
                  </Form>
                </MDBox>
              </MDBox>
            )
          }}
        </Formik>
      </MDBox>
    </Modal>
  )
}
