import {getIn} from "formik";
import MDBox from "../../../components/MDBox";
import FormikInput from "../../../components/FormikInput";
import {Button, Grid} from "@mui/material";
import MDTypography from "../../../components/MDTypography";
import {QUIZZES} from "../constants";

export const QuizzesForm = ({ formik, quizzesProps, isViewDetails }) => {
  const parentPath = quizzesProps.name
  const valuesArray = getIn(formik.values, parentPath)

  const optionRender = (mapPath, options, optionsIndex) => {

    return (
      <Grid item container md={12} key={`id-${optionsIndex}`}>
        <Grid md={8} item>
          <FormikInput
            name={`${mapPath}.options[${optionsIndex}].option`}
            label={`Option ${optionsIndex + 1}`}
            disabled={isViewDetails}
            errors={formik.errors}
          />
        </Grid>
        <Grid item md={4} >
          <FormikInput
            name={`${mapPath}.options[${optionsIndex}].is_correct`}
            label={'Is Correct'}
            type={'checkbox'}
            disabled={isViewDetails}
            checked={options[optionsIndex]?.is_correct}
            onChange={(e) => {
              const { checked } = e.target
              formik.setFieldValue(`${mapPath}.options[${optionsIndex}].is_correct`, checked)
            }}
          />
        </Grid>
      </Grid>
    )
  }

  return (
    <MDBox>
      <Button disabled={valuesArray?.length === 4 || isViewDetails} onClick={()=> quizzesProps.push({...QUIZZES()})}>Add more</Button>
      {valuesArray?.length > 0 && <MDBox>
          {valuesArray.map((item, quizzesIndex) => {
            const mapPath = `${parentPath}.${quizzesIndex}`
              return (
                <Grid container key={`id-${quizzesIndex}`}>
                  <MDBox display={'flex'} justifyContent={'center'} width={'100%'}>
                    <MDTypography color={'primary'} my={2}>{`Quiz ${quizzesIndex + 1}`}</MDTypography>
                  </MDBox>
                  <Grid item md={12}>
                    <FormikInput name={`${mapPath}.title`} label={'Question'} disabled={isViewDetails} errors={formik.errors}/>
                  </Grid>
                  <Grid item md={12}>
                    <FormikInput name={`${mapPath}.show_on`} label={'Show On'} disabled={isViewDetails} errors={formik.errors}/>
                  </Grid>
                  {[0, 1, 2, 4].map((_, optionsIndex) => optionRender(mapPath, item.options, optionsIndex))}
                  {quizzesIndex !== 0 && <Button disabled={isViewDetails} onClick={()=> quizzesProps.remove(quizzesIndex)}>Delete</Button>}
                </Grid>
              )
            }
          )}
        </MDBox>
      }
    </MDBox>

  )
}

