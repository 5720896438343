import AdminLayout from "../../components/AdminLayout";
import MDBox from "../../components/MDBox";
import React, {useEffect, useState} from "react";
import {useApi} from "../../services/helpers";
import MDInput from "../../components/MDInput";
import SearchBar from "../../components/SearchBar";
import Autocomplete from "@mui/material/Autocomplete";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";
import {ApproveDenyModal} from "./ApproveDenyModal";
import {dataTableModel, renderTableRow} from "./utils";
import DataTable from "../../components/DataTable";

function AdminRedeemPoints() {
  const api = useApi()
  const [loading, setLoading] = useState(false);
  const [datatable, setDatatable] = useState({...dataTableModel})
  const [searchQuery, setSearchQuery] = useState('');
  const [paginationData, setPaginationData] = useState({
    counts: 0,
    itemsPerPage: 0,
    currentPage: 1,
    ordering: ""
  });
  const values = [
    {value: 1, label: 'All'},
    {value: 2, label: 'Admin'},
    {value: 3, label: 'Marketing Agent'}
  ];
  const [value, setValue] = useState(values[0]);
  const [openDeny, setOpenDeny] = useState(false);
  const [openConfirmDe, setOpenConfirmCreateAdmin] = useState(false);
  const [openApprove, setOpenApprove] = useState(false);
  const [openCreateAdmin, setOpenCreateAdmin] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [dataView, setDataView] = useState(null)
  const [userId, setUserId] = useState(null)
  const [typeUser, setTypeUser] = useState("")
  const handleApprove = (values) => {
    ApprovePoints(values)
    setUserId(null)
    setOpenApprove(false)
  }
  const handleCloseApprove = () => {
    setOpenApprove(false)
  }

  const handleCloseDeny = () => {
    setOpenDeny(false)
  }
  const handleConfirmDeny = () => {
    DenyPoints(userId)
    setOpenDeny(false)
  }
  const getRedeemPoints = (search, page = 1, ordering = "") => {
    setLoading(true)
    api.getRedeemPoints({search, page, page_size: pageSize, type:typeUser, ordering}).handle({
        onSuccess: (result) => {
          const {count, end, start, data} = result
          const tmp = {...dataTableModel}
          tmp.rows = data?.map(e => renderTableRow(e, setUserId, setOpenApprove, setDataView, setOpenDeny))
          setDatatable(tmp)
          setPaginationData(value => ({
            ...value,
            counts: count,
            start,
            end,
            itemsPerPage: data?.length,
            ordering
          }))
        },
        errorMessage: 'Error getting Redeem Points',
        onError: err => {
          console.log(err)
        },
        onFinally: () => setLoading(false)
      }
    )
  }

  const DenyPoints = (id) => {
    api.denyRedeemPoints(id).handle({
      onSuccess: () => {
        getRedeemPoints();
      },
      errorMessage: "Sorry an error occurred",
      successMessage: "User points denied",
    });
  }

  const ApprovePoints = (values) => {
    api.approveRedeemPoints(values.id).handle({
      onSuccess: (result) => {
        // SendPayment(values)
        getRedeemPoints();
      },
      errorMessage: "Sorry an error occurred",
    });
  }

  // const SendPayment = (data) => {
  //   api.sendPayment(data).handle({
  //     onSuccess: () => {
  //       setDataView(null)
  //       getRedeemPoints();
  //     },
  //     errorMessage: "Sorry an error occurred",
  //     successMessage: "Payment sent successfully",
  //   });
  // }

  const getFilteredPage = () =>{
    let filters =  {search: searchQuery, page: 1}
    setPaginationData(value => ({...value, currentPage: filters.page}))
    return filters
  }

  useEffect(() => {
    getRedeemPoints()
  }, [])

  useEffect(() => {
    const {search, page} = getFilteredPage()
    getRedeemPoints(search, page)
  }, [searchQuery, pageSize])

  return (
    <AdminLayout
      title={'Redeem Points'}
    >
      <MDBox width={"100%"}>
        <MDBox display={"flex"} justifyContent={"space-between"}>
        </MDBox>
        <MDBox display={"flex"} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}
               mb={"10px"}>
          <MDBox display={"flex"} flexDirection={"row"} alignItems={"center"}>

            <SearchBar loading={loading} setSearchQuery={setSearchQuery}/><MDBox>
          </MDBox>
          </MDBox>
        </MDBox>
        <MDBox>
          <DataTable
            loading={loading}
            table={datatable}
            currentPage={paginationData.currentPage}
            numberOfItems={paginationData.counts}
            numberOfItemsPage={paginationData.itemsPerPage}
            start={paginationData.start}
            end={paginationData.end}
            searchFunc={getRedeemPoints}
            searchQuery={searchQuery}
            setPageSize={setPageSize}
            pageSize={pageSize}
            onPageChange={page => {
              const {search} = getFilteredPage()
              getRedeemPoints(search, page)
              setPaginationData(value => ({...value, currentPage: page}))
            }}
          />
        </MDBox>
        <ApproveDenyModal
          title={'Deny User'}
          open={openDeny}
          data={dataView}
          handleClose={handleCloseDeny}
          handleConfirm={handleConfirmDeny}
        />
        <ApproveDenyModal
          title={'Approve Payment'}
          open={openApprove}
          data={dataView}
          handleClose={handleCloseApprove}
          handleConfirm={handleApprove}
        />
      </MDBox>
    </AdminLayout>
  );
}

export default AdminRedeemPoints;
