/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Image
import logo from "assets/images/logos/logo.png"
import MDTypography from "../../components/MDTypography";
import {CircularProgress} from "@mui/material";

export const SuccessDocuSign = () => {
  return (
      <MDBox bgColor={'#000'} height={'100vh'} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} px={2}>
        <MDBox component={'img'} src={logo} alt={'logo'} sx={{ width: 90, height: 90 }}/>
        <MDTypography color={'white'} textAlign={'center'} pb={2}>We are verifying the signed documents, this will take a moment</MDTypography>
        <CircularProgress size={30} color="primary"/>
      </MDBox>
  );
}
