import React from "react";
import MDInput from "../MDInput";
import Autocomplete from "@mui/material/Autocomplete";

export const CustomSelect = (props) => {
  const {onChange, readOnly, values, sx, formik, defaultValue, type, label, value, fullWidth=true, placeholder, overrideError, multiline, ...rest} = props

  return (
    <Autocomplete
      defaultValue={defaultValue}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      disableClearable
      value={value}
      readOnly={readOnly}
      options={values}
      onChange={onChange}
      size="small"
      renderInput={(params) =>
        <MDInput
          {...params}
          label={label}
          placeholder={placeholder}
          sx={[{
            width: "100%",
            backgroundColor: "#2e2e2e",
            color: "#fff",
            padding: "5px",
            display: "flex",
            alignItems: "flex-start",
          }, sx]}
        />
      }
      {...rest}
    />
  )
}
