import MDBox from "../../components/MDBox";
import {Form, Formik} from "formik";
import MDTypography from "../../components/MDTypography";
import FormikInput from "../../components/FormikInput";
import MDButton from "../../components/MDButton";
import {Modal} from "@mui/material";
import {useRef} from "react";
import * as Yup from "yup";


const validationSchema = Yup.object().shape({
  description: Yup.string(),
})


export const ApproveDenyModal = ({title, handleClose, open, data, handleConfirm}) => {
  const formikRef = useRef();
  const initialValues = {
    description: data?.description ?? '',
    id: data?.id ?? '',
  };


  return (
    <Modal open={open} onClose={handleClose}>
      <MDBox sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        backgroundColor: '#282828',
        borderRadius: '10px',
        transform: 'translate(-50%, -50%)',
        width: 400,
        boxShadow: 24,
      }}>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange={false}
          onSubmit={values => {
            handleConfirm(values)
          }}
        >
          {(formik) => {
            return (
              <MDBox>
                <MDTypography pt={2}
                              sx={{textAlign: 'center'}}>{title}</MDTypography>
                <MDBox p={3}>
                  <Form>
                    <MDBox>
                    </MDBox>
                    <FormikInput type={"textarea"} name={'description'} label={'Description'} errors={formik.errors}/>
                    <MDBox display={'flex'} justifyContent={'center'} alignItems={'center'} gap={2} pt={2}>
                      <MDButton color={'primary'} onClick={() => {
                        formik.handleSubmit()
                      }}>Submit</MDButton>
                      <MDButton variant={'outlined'} onClick={() => {
                        handleClose()
                      }}>Cancel</MDButton>
                    </MDBox>
                  </Form>
                </MDBox>
              </MDBox>
            )
          }}
        </Formik>
      </MDBox>
    </Modal>
  )
}
